import { useState, useEffect } from 'react';
import { ToastMessageType } from '../components/Toaster/Type';

// Custom hook to track MFA completion based on toastMessage status
export function useWaitForMFA(toastMessage: ToastMessageType): boolean {
  const [isMFACompleted, setIsMFACompleted] = useState(false);

  useEffect(() => {
    if (
      toastMessage.severity === 'success' &&
      toastMessage.snackbarMsg.includes('Your phone number is verified')
    ) {
      setIsMFACompleted(true); // Set MFA as completed
    }
  }, [toastMessage]);

  return isMFACompleted;
}
