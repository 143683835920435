import { Auth } from 'aws-amplify';
import React from 'react';
import { GlobalContext } from '../components/GlobalContext';

export type SMSMFAStateType = {
  user: object | null;
  smsCode: string;
  isVerifying: boolean;
  error: string;
  success: boolean;
};

export const useSMSMFA = (user: object, smsCode: string) => {
  const { setIsOpenToast, setToastMessage } = React.useContext(GlobalContext);
  const [mfaState, setMfaState] = React.useState<SMSMFAStateType>(
    {} as SMSMFAStateType
  );

  React.useEffect(() => {
    setMfaState({
      user,
      smsCode,
      isVerifying: false,
      error: '',
      success: false,
    });
  }, [user, smsCode]);

  React.useEffect(() => {
    if (mfaState.success) {
      setToastMessage({
        severity: 'success',
        snackbarMsg: 'Verification success!',
        isOpen: true,
      });
      setIsOpenToast(true);
    }
  }, [mfaState.success]);

  const getErrorMessage = (error: any): string => {
    if (!error.code) {
      return 'Unknown error during MFA verification: ' + error;
    }
    switch (error.code) {
      case 'NotAuthorizedException':
        return 'Invalid code or the session has expired. Please try again.';
      case 'CodeMismatchException':
        return 'Incorrect code entered. Please try again.';
      case 'ExpiredCodeException':
        return 'The code has expired. Please request a new one.';
      case 'UserNotFoundException':
        return 'User not found. Please check the user credentials.';
      default:
        return 'Error during MFA verification: ' + error.message;
    }
  };

  const verifySMSCode = async (user: object, smsCode: string) => {
    // console.log('Verify SMS code...');

    try {
      const loggedInUser = await Auth.confirmSignIn(
        user, // User object returned from the Auth.signIn method
        smsCode, // The SMS MFA code input by the user
        'SMS_MFA' // Challenge type
      );
      // console.log('Successfully signed in:', loggedInUser);
      setMfaState((prevState) => ({
        ...prevState,
        success: true,
        error: '',
      }));
    } catch (error: any) {
      const errMsg = getErrorMessage(error);
      setMfaState((prevState) => ({
        ...prevState,
        error: errMsg,
        success: false,
      }));
    }
  };

  const handleMFA = async () => {
    setMfaState((prevState) => ({
      ...prevState,
      isVerifying: true,
      error: '',
    }));

    if (!mfaState.user || !mfaState.smsCode) {
      setMfaState((prevState) => ({
        ...prevState,
        error: 'User or SMS code is missing.',
        isVerifying: false,
      }));
      return;
    }

    await verifySMSCode(mfaState.user, mfaState.smsCode);

    setMfaState((prevState) => ({
      ...prevState,
      isVerifying: false,
    }));
  };

  return {
    SMSMFAState: mfaState,
    handleMFA,
  };
};
