import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { VerifyPhoneNumberForm } from '../VerifyPhoneNumberForm';
import { VerificationCodeForm } from '../VerificationCodeForm';
import { MFASteps } from '../../View';

interface MFAProps {
  steps: MFASteps;
  setSteps: React.Dispatch<React.SetStateAction<MFASteps>>;
}

export const MFA: React.FC<MFAProps> = ({ steps, setSteps }) => {
  switch (steps.step) {
    case 0:
      return <VerifyPhoneNumberForm setSteps={setSteps} steps={steps} />;
    case 1:
      return <VerificationCodeForm setSteps={setSteps} steps={steps} />;
    default:
      return <VerifyPhoneNumberForm setSteps={setSteps} steps={steps} />;
  }
};
export const useMFAStyles = makeStyles((theme: Theme) => ({
  backButtonContainer: {
    padding: theme.spacing(2, 0, 2, 3),
  },
  backButton: {
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  title: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.dark,
    lineHeight: '32px',
    padding: theme.spacing(2.5, 0, 2.375, 3),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  form: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',

    gap: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  root: {
    width: '100%',
  },
  resetPwCard: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  instructions: {
    fontSize: 16,
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightMedium,
  },
  inputLabel: {
    fontSize: 14,
    height: 24,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['700'],
  },
  inputField: {
    height: 48,
    borderRadius: 8,
    fontSize: 16,
  },
  submitButton: {
    height: 48,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 8,

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
  backToLoginButton: {
    width: 'fit-content',
    padding: 0,
    height: 'fit-content',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    borderRadius: 0,
  },
  backToLogin: {
    fontSize: 14,
    fontWeight: 600,
    height: 24,
    color: theme.palette.secondary.dark,
    borderBottom: '2px solid #434343',
    paddingBottom: theme.spacing(0.5),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  errorMsg: {
    fontSize: 12,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
  nameInput: {
    height: 48,
    fontSize: 16,
    width: '100%',
    borderRadius: 8,
    '&.MuiInputBase-root.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[400],
    },
  },
  textBtnWithBottomLine: {
    width: 'fit-content',

    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 600,
    padding: 0,
    borderRadius: 0,
    borderBottom: `2px solid rgba(12, 119, 216, 0.3)`,
    justifyContent: 'flex-start',

    '& .MuiButton-startIcon': {
      marginRight: 0,
    },

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&:disabled': {
      color: theme.palette.grey[500],
      borderBottom: `2px solid rgba(136, 136, 136, 0.3)`,
      backgroundColor: 'transparent',
    },
  },
  navigateNext: {
    width: 24,
    height: 24,
    transform: `rotate(180deg)`,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
}));
