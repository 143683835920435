import {
  Button,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { GlobalContext } from '../../../../../components/GlobalContext';
import { UserRole } from '../../../../../enum/Users';
import { formatePhoneNumber } from '../../../../../sharePipe/normalizePhoneNumberInput';
import { useOutlinedInputStyles } from '../../../../../styles/outlineInputStyle';
import { RoleSelect } from '../../../../Users/MsoAdmin/components/RoleSelect';
import { UpdateUserInput } from '../../../../Users/MsoAdmin/EditUsers/Container';
import { useEditProfileStyles } from './Styles';
import { useCallbackPrompt } from '../../../../../hooks/useCallbackPrompt';
import { LeaveEditDialogBox } from '../../../../../components/DialogBox';
import { useNewRoleOptions } from '../../../../../hooks/useNewRoleOptions';
import { useWaitForMFA } from '../../../../../hooks/useWaitMFA';

const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;
const ITEM_NUM = 5;

interface EditProfileFormViewProps {
  editMyProfile: (input: any) => any;
  roleOptions: any[];
}

export const EditProfileFormView = (props: EditProfileFormViewProps) => {
  const classes = useEditProfileStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    showDialog
  );

  const { loggedInUser, toastMessage } = React.useContext(GlobalContext);
  const {
    control,
    register,
    errors,
    formState,
    handleSubmit,
  } = useForm<UpdateUserInput>({
    defaultValues: {
      firstName: loggedInUser?.firstName,
      lastName: loggedInUser?.lastName,
      userId: loggedInUser?.id,
      phone: formatePhoneNumber(loggedInUser?.phone || ''),
      email: loggedInUser?.email,
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    setIsOpenToast,
    setToastMessage,
    setMFADialogState,
    MFADialogState,
  } = React.useContext(GlobalContext);
  const [role, setRole] = React.useState<UserRole>();

  const location = useLocation();
  const passingData = location.state as any;
  // const userData = passingData.userData as User;
  const [preUrl, setPreUrl] = React.useState<string>('');

  React.useEffect(() => {
    if (passingData) {
      setPreUrl(passingData.preUrl);
    } else {
      setPreUrl('/dashboard');
    }
  }, [passingData]);
  React.useEffect(() => {
    if ((formState.isDirty || role) && !showDialog) {
      setShowDialog(true);
    }
  }, [formState.isDirty, role]);

  const isMFACompleted = useWaitForMFA(toastMessage);

  const handleMFA = () => {
    return new Promise((resolve, reject) => {
      if (isMFACompleted) {
        resolve(true);
      } else {
        reject(false);
      }
    });
  };

  async function onSubmit(input: UpdateUserInput) {
    // call api
    setShowDialog(false);
    if (loggedInUser?.phone !== phoneNumber.replaceAll('-', '')) {
      // verify phone number
      setMFADialogState({
        showMFADialog: true,
        phoneNumber: phoneNumber.replaceAll('-', ''),
        userId: loggedInUser!.id,
        verifyButtonClicked: false,
        skipForNow: false,
      });
      await handleMFA();
    }

    try {
      await props.editMyProfile({
        userId: loggedInUser?.id,
        firstName: input.firstName,
        lastName: input.lastName,
        email: loggedInUser?.email,
        roleId: role?.roleId,
        phone: phoneNumber.replaceAll('-', ''),
      });
      // go back to previous page and show success toaster
      navigate(preUrl);
      setToastMessage({
        snackbarMsg: `Your Profile has been updated!`,
        severity: 'success',
        isOpen: true,
      });
      setIsOpenToast(true);
    } catch {
      // go back to previous page and show failed toaster
      navigate(preUrl);
      setToastMessage({
        snackbarMsg: `Fail to update, please try later!`,
        severity: 'error',
        isOpen: true,
      });
      setIsOpenToast(true);
    }
  }

  const [phoneNumber, setPhoneNumber] = React.useState(
    formatePhoneNumber(loggedInUser?.phone || '')
  ); // userData.phoneNumber

  React.useEffect(() => {
    setPhoneNumber(formatePhoneNumber(loggedInUser?.phone || ''));
  }, [loggedInUser?.phone]);

  // 123-456-9999
  const formatPhoneNumber = (phone: string) => {
    let input = phone.replace(/\D/g, '');
    let size = input.length;
    if (size > 0) {
      input = '' + input;
    }
    if (size > 3) {
      input = input.slice(0, 3) + '-' + input.slice(3, 11);
    }
    if (size > 6) {
      input = input.slice(0, 7) + '-' + input.slice(7, 11);
    }
    return input;
  };

  const handlePhoneNumberChange = (e: any) => {
    setPhoneNumber(formatPhoneNumber(e.target.value));
    setShowDialog(true);
  };

  const newRoleOptions = useNewRoleOptions(props.roleOptions);

  return (
    <div className={classes.root}>
      <LeaveEditDialogBox
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.card} elevation={1}>
          <Grid container>
            <Grid item xs={12} className={classes.cardHeader}>
              <Typography className={classes.cardTitle}>
                Edit Profile
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={3}
              className={classes.cardContent}
            >
              <Grid item xs={6}>
                <Typography className={classes.inputLabel}>
                  First name
                </Typography>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ onChange, value, name }) => {
                    return (
                      <>
                        <OutlinedInput
                          fullWidth
                          // autoFocus
                          inputRef={register({
                            required: {
                              value: true,
                              message: 'You must enter your name',
                            },
                            minLength: {
                              value: 2,
                              message: 'Name must be at least 2 characters',
                            },
                            maxLength: {
                              value: 26,
                              message: 'Name must be less than 26 characters',
                            },
                          })}
                          name={name}
                          defaultValue={value}
                          onChange={onChange}
                          error={
                            !!errors.firstName?.message ||
                            !!errors.firstName?.type
                          }
                          className={classes.nameInput}
                          placeholder="First name"
                          classes={outlinedInputClasses}
                        />
                        {errors.firstName &&
                          errors.firstName.type === 'required' && (
                            <Typography className={classes.subLabelError}>
                              {errors.firstName.message}
                            </Typography>
                          )}
                        {errors.firstName &&
                          errors.firstName.type === 'minLength' && (
                            <Typography className={classes.subLabelError}>
                              {errors.firstName.message}
                            </Typography>
                          )}
                        {errors.firstName &&
                          errors.firstName.type === 'maxLength' && (
                            <Typography className={classes.subLabelError}>
                              {errors.firstName.message}
                            </Typography>
                          )}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.inputLabel}>
                  Last name
                </Typography>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ onChange, value, name }) => {
                    return (
                      <>
                        <OutlinedInput
                          fullWidth
                          inputRef={register({
                            required: {
                              value: true,
                              message: 'You must enter your name',
                            },
                            minLength: {
                              value: 2,
                              message: 'Name must be at least 2 characters',
                            },
                            maxLength: {
                              value: 26,
                              message: 'Name must be less than 26 characters',
                            },
                          })}
                          name={name}
                          defaultValue={value}
                          onChange={onChange}
                          error={
                            !!errors.lastName?.message ||
                            !!errors.lastName?.type
                          }
                          className={classes.nameInput}
                          placeholder="Last name"
                          classes={outlinedInputClasses}
                        />

                        {errors.lastName &&
                          errors.lastName.type === 'required' && (
                            <Typography className={classes.subLabelError}>
                              {errors.lastName.message}
                            </Typography>
                          )}
                        {errors.lastName &&
                          errors.lastName.type === 'minLength' && (
                            <Typography className={classes.subLabelError}>
                              {errors.lastName.message}
                            </Typography>
                          )}
                        {errors.lastName &&
                          errors.lastName.type === 'maxLength' && (
                            <Typography className={classes.subLabelError}>
                              {errors.lastName.message}
                            </Typography>
                          )}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.inputLabel}>
                  Email address
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  render={({ onChange, value, name }) => (
                    <OutlinedInput
                      fullWidth
                      disabled
                      inputRef={register({
                        required: true,
                        minLength: 1,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      })}
                      name={name}
                      defaultValue={value}
                      onChange={onChange}
                      error={!!errors.email?.message || !!errors.email?.type}
                      className={classes.nameInput}
                      placeholder="Enter email address"
                      classes={outlinedInputClasses}
                    />
                  )}
                />
                <Typography className={classes.subLabel}>
                  We do not support changing email yet.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RoleSelect
                  state={role}
                  setState={setRole}
                  isMultiple={false}
                  formType={'UserRole'}
                  itemsTypes={'Item'}
                  inputTitle={'Role(s)'}
                  placeholder={'Select Title(s)'}
                  value={loggedInUser?.role}
                  droplistAttr={{
                    maxHeight: ITEM_HEIGHT * ITEM_NUM + ITEM_PADDING_TOP,
                  }}
                  items={newRoleOptions}
                  classes={{
                    multipleSelect: classes.multipleSelect,
                    formControl: classes.formControl,
                    inputTitle: classes.inputTitle,
                    placeholder: classes.placeholderText,
                    placeholderSelected:
                      classes.insurancePlaceholderTextSelected,
                    select: classes.insuranceSelect,
                    list: classes.insuranceList,
                    listItem: classes.insuranceListItem,
                    itemText: classes.insuranceListItemText,
                    itemHelper: classes.insuranceListitemHelper,
                    selected: classes.selected,
                    menuItem: classes.insuranceMenuItem,
                    menuItemText: classes.insuranceMenuItemText,
                    selectMenuContent: classes.selectMenuContent,
                    inputHelper: classes.inputHelper,
                  }}
                  hasAvatar={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.inputLabel}>Phone</Typography>
                <OutlinedInput
                  fullWidth
                  inputProps={{
                    required: true,
                  }}
                  name={'phone'}
                  defaultValue=""
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={
                    phoneNumber?.length > 0 &&
                    phoneNumber.match(/^[2-9][0-9]{2}-[0-9]{3}-[0-9]{4}$/i) ==
                      null
                  }
                  className={classes.nameInput}
                  placeholder="Enter Phone Number(s): e.g. 234-456-7890"
                  classes={outlinedInputClasses}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.cardFooter}>
              <Button
                type="submit"
                className={classes.nextButton}
                disabled={
                  !formState.isValid ||
                  !(
                    phoneNumber?.match(/^[2-9][0-9]{2}-[0-9]{3}-[0-9]{4}$/i) !=
                    null
                  )
                }
              >
                Update User
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </div>
  );
};
