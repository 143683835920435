import React from 'react';

import { useGetUsersByAccountIdQuery } from './index.generated';
import { AllUsersView } from './View';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import {
  AccountStatus,
  CognitoStatus,
  RoleType,
  createOperator,
  createUser,
  createUserRole,
} from '../../../../../enum/Users';
import { CareGapPatientNotFound } from '../../../../CareGaps/Home/tabs/Population/components/CareGapPatientTableNotFound';
import { stringToBoolean } from '../../../../../sharePipe/stringToBoolean';

type Props = {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  setTotalNumUsers: (input: number) => void;
  actionsFooter: any[];
  setActionsFooter: any;
  isSearching: boolean;
  searchName: string;
};

export function AllUsersViewContainer(props: Props) {
  const [userRows, setUserRows] = React.useState<any>([]);
  const [userCount, setUserCount] = React.useState(0);

  const [sortBy, setSortBy] = React.useState({
    field: 'firstName',
    method: 'asc' as const,
  });
  const defaultInput = {
    limit: props.limit ?? 10,
    skip: props.skip ?? 0,
    sortBy: [{ ...sortBy }],
    showAll: true,
  };
  const users = useGetUsersByAccountIdQuery({
    variables: {
      getUsersByAccountIdRequest: props.isSearching
        ? {
            ...defaultInput,
            andName: props.searchName,
            showAll: true,
          }
        : defaultInput,
    },
  });
  React.useEffect(() => {
    if (users.data) {
      if (users.data.getUsersByAccountId) {
        setUserRows(
          users.data.getUsersByAccountId?.results.map((user: any) => {
            // console.log('getUsersByAccountId', user);
            // convert cognito status into required status
            let userStatus = 'Unknown';

            if (user.cognitoStatus === CognitoStatus.FORCE_CHANGE_PASSWORD) {
              userStatus = AccountStatus.PENDING;
            } else {
              if (user.status === 'enable') {
                userStatus = AccountStatus.ACTIVE;
              } else if (user.status === 'disable') {
                userStatus = AccountStatus.DEACTIVATED;
              }
            }

            let createdByFirstName = user.createdBy?.firstName
              ? user.createdBy!.firstName
              : 'System';
            let createdByLastName = user.createdBy?.lastName
              ? user.createdBy!.lastName
              : 'Admin';

            return createUser(
              user.id,
              user.cognitoId,
              user.firstName,
              user.lastName,
              user.email,
              user.role?.roleName === 'Admin'
                ? RoleType.CARE_MANAGER
                : user.role?.roleName,
              createUserRole(
                user.role?.id || '',
                user.role?.roleName || 'User'
              ),
              userStatus,
              user.createdAt,
              createOperator(
                createdByFirstName + ' ' + createdByLastName,
                user.createdBy!.user!
              ),
              user.updatedAt,
              createOperator(
                user.updatedBy?.firstName + ' ' + user.updatedBy?.lastName,
                user.updatedBy?.user!
              ),
              user?.phone || '',
              user.clinics,
              user.divisions,
              user.clinicAndDivisionPairs,
              stringToBoolean(user.phoneVerified),
              stringToBoolean(user.mfaEnabled)
            );
          })
        );
        setUserCount(users.data.getUsersByAccountId?.count || 0);
        if (props.isSearching !== true && !props.searchName) {
          props.setTotalNumUsers(users.data.getUsersByAccountId?.count || 0);
        }
      } else {
        setUserRows([]);
        setUserCount(0);
      }
    }
  }, [users.data, props.isSearching]);
  const noMatchTextForSearching =
    'We are not able to find matching records, you can search by user full name.';

  // console.log('userRows', userRows);
  if (!users.called || users.loading) {
    return <LoadingSpinner style={{ height: `calc(100vh - 235px)` }} />;
  }

  return (
    <>
      {props.isSearching && userCount == 0 ? (
        <CareGapPatientNotFound
          isSearching={props.isSearching}
          noMatchTextForSearching={noMatchTextForSearching}
        />
      ) : (
        <AllUsersView
          refetchDataFunc={{
            func: users.refetch,
            params: {
              limit: props.limit ?? 10,
              skip: props.skip ?? 0,
              sortBy: [{ ...sortBy }],
            },
          }}
          sortBy={{ ...sortBy }}
          setSortBy={setSortBy}
          userRows={userRows}
          limit={props.limit ?? 10}
          skip={props.skip ?? 0}
          setLimit={props.setLimit}
          setSkip={props.setSkip}
          count={userCount}
          actionsFooter={props.actionsFooter}
          setActionsFooter={props.setActionsFooter}
        />
      )}
    </>
  );
}
