import { Maybe } from '../app.types.generated';

/**
 * Converts a string value to a boolean.
 *
 * @param value - The value to be converted.
 * @returns The boolean representation of the string value. Returns false if the value is `null`, `undefined` or ``.
 *          Returns true only if the string value is "true".
 */
export function stringToBoolean(
  value: Maybe<string> | undefined | string
): boolean {
  if (value === null || value === undefined || value === '') {
    return false;
  }
  return value.toLowerCase() === 'true'; // Will return true only if the string is "true"
}
