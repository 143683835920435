import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';

interface SessionTimeoutDialogProps {
  onContinue: () => void;
  lessThanOneMinute: boolean;
  isInactive: boolean;
  onSignIn: () => void;
}

export const SessionTimeoutDialog: React.FC<SessionTimeoutDialogProps> = ({
  onContinue,
  lessThanOneMinute,
  isInactive,
  onSignIn,
}) => {
  const classes = useStyles();

  const [sessionStatus, setSessionStatus] = React.useState(-1);

  React.useEffect(() => {
    if (lessThanOneMinute && !isInactive) {
      setSessionStatus(0);
    }

    if (isInactive) {
      setSessionStatus(1);
    }
  }, [lessThanOneMinute, isInactive]);

  const dialogContentMap: { [key: number]: JSX.Element } = {
    0: (
      <span>
        Your session is about to expire. You will be logged out in 60 seconds.
      </span>
    ),
    1: (
      <span>
        Your session is expired due to inactive. Please sign in again.
      </span>
    ),
  };
  const dialogActionMap: { [key: number]: JSX.Element | null } = {
    0: (
      <Button
        variant="contained"
        disableElevation
        className={classes.signInBtn}
        type="button"
        onClick={onContinue}
      >
        Continue Session
      </Button>
    ),
    1: (
      <Button
        variant="contained"
        disableElevation
        className={classes.signInBtn}
        type="button"
        onClick={onSignIn}
      >
        Sign In
      </Button>
    ),
  };

  return (
    <div role="SessionTimeoutDialog" className={classes.root}>
      <Dialog
        className={classes.dialogContainer}
        open={true}
        onClose={() => {}}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="SessionTimeoutDialog-title"
          style={{
            borderBottom: '1px solid #D1D1D1',
          }}
          className={classes.dialogTitle}
        >
          Session Time out
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {dialogContentMap[sessionStatus]}
        </DialogContent>

        <div className={classes.dialogAction}>
          {dialogActionMap[sessionStatus]}
        </div>
      </Dialog>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dialogTitle: {
    '& h2': {
      color: theme.palette.grey[700],
      fontWeight: 700,
      lineHeight: '32px',
    },
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: 516,
      height: 'fit-content',
    },
  },
  dialogContent: {
    padding: theme.spacing(3, 3, 4, 3),
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette.grey[600],
  },
  dialogAction: {
    borderTop: '1px solid #D1D1D1',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0, 2, 3),
  },
  backButton: {
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
  signInBtn: {
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    lineHeight: 1.5,

    background: theme.palette.primary.main,
    borderRadius: 8,
    width: 'fit-content',
    height: 40,
    textAlign: 'center',
    padding: theme.spacing(0, 1),
    marginRight: theme.spacing(2.875),
    '&:hover': {
      backgroundColor: '#1b8cf3',
    },

    '&:disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      background: theme.palette.primary.main,
    },
  },
}));
