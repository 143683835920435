import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {GlobalContext} from "../../../components/GlobalContext";
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MainRegion } from '../../../components/MainRegion';
import { Grid, Typography } from '@material-ui/core';
import { Auth } from '@aws-amplify/auth';
import LogoWithAppNameColored from '../../../assets/Login/LogoWithAppNameColored.svg';
import MdlandLogoColored from '../../../assets/Login/MdlandLogoColored.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Signer } from '@aws-amplify/core';

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: 280,
    flexGrow: 1,
    justifyContent: 'center',
  },
  footerText: {
    fontSize: 14,
    paddingRight: theme.spacing(1),
    color: '#434343',
    fontWeight: 400,
    lineHeight: '19px',
  },
  footer: {
    position: 'absolute',
    bottom: 56,
    display: 'flex',
    alignItems: 'center',
  },
}));

export function IClinicLogin() {
  const classes = useStyles();
  const { setIsLoginFromiClinic } = React.useContext(GlobalContext);const backendUrl = 'https://67ae8xr6q3.execute-api.us-east-1.amazonaws.com/prod/landingUserRegister';
  const [error, setError] = React.useState<any>(null);
  const [firstLoading, setFirstLoading] = React.useState(true);
  const navigate = useNavigate();
  const [origin, setOrigin] = useState('');
  const [tokenApplied, setTokenApplied] = useState(false);
  const [messageHandled, setMessageHandled] = useState(false); // New state variable

  async function applyToken(token: any, targetUser: any) {
    const keycloakToken = token;
    try {
      // Get AWS credentials using the Keycloak token
      const credentials = await Auth.federatedSignIn(
        'authsrv.mdland.net/auth/realms/mdland_internal',
        { token: keycloakToken, expires_at: Date.now() + 3600 * 1000 },
        { name: 'FederatedUser' }
      );
      const awsAccessKey = credentials.accessKeyId;
      const awsSecretKey = credentials.secretAccessKey;
      const awsSessionToken = credentials.sessionToken;
      Auth.configure({
        credentials: {
          accessKeyId: awsAccessKey,
          secretAccessKey: awsSecretKey,
          sessionToken: awsSessionToken,
        },
      });
      const {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      } = await Auth.currentUserCredentials();

      const authConfiguration = Auth.configure(null);

      const signaturString = token.split('.')[2];
      const { headers } = Signer.sign(
        {
          url: backendUrl,
          data: JSON.stringify({
            signature: signaturString,
            userId: targetUser
          }),
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
        },
        {
          access_key: accessKeyId,
          secret_key: secretAccessKey,
          session_token: sessionToken,
        },
        { region: authConfiguration.region, service: 'execute-api' }
      );

      const signedRequest = {
        method: 'POST',
        url: backendUrl,
        headers: {
          'Content-Type': 'application/json',
          ...headers
        },
        body: JSON.stringify({
          signature: signaturString,
          userId: targetUser
        }),
      };
      const tokenResp: any = await fetch(signedRequest.url, {
        method: signedRequest.method,
        headers: signedRequest.headers,
        body: signedRequest.body,
        'mode': 'cors',
      });

      if (tokenResp.ok) {
        const tokenJson = await tokenResp.json();
        const user_token: any = tokenJson.token;
        if (user_token && user_token.length > 0) {
          await Auth.signOut();
          const user_credentials = await Auth.federatedSignIn(
            'authsrv.mdland.net/auth/realms/mdland_internal',
            { token: user_token, expires_at: Date.now() + 3600 * 1000 },
            { name: 'FederatedUser' }
          );
          Auth.configure({
            credentials: {
              accessKeyId: user_credentials.accessKeyId,
              secretAccessKey: user_credentials.secretAccessKey,
              sessionToken: user_credentials.sessionToken,
            },
          });
          setIsLoginFromiClinic(true);
          console.log('## Login in from iClinic')
          navigate('/dashboard'); // Navigate to dashboard after successful token application
        }
      } else {
        console.error('Network response was not ok.');
      }

    } catch (error) {
      console.error('Error getting Cognito token:', error);
      throw error;
    }
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (messageHandled) return; // Check if the message has already been handled
      setMessageHandled(true); // Mark the message as handled

      setOrigin(event.origin);
      try {
        if (event.data) {
          console.log('Received message:', event.data)
          const json = JSON.parse(event.data);
          if (json.token && json.user && firstLoading && !tokenApplied) {
            console.log('Token:', json.token, 'User:', json.user);
            applyToken(json.token, json.user).then(() => {
              setFirstLoading(false);
              setTokenApplied(true);
            });
          }
        }
      } catch (e: any) {
        console.log(e);
      }
    };

    window.addEventListener('message', handleMessage, false);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [firstLoading, navigate, tokenApplied, messageHandled]); // Add messageHandled to the dependency array

  useEffect(() => {
    // Check if token is already available
    const checkToken = async () => {
      try {
        const currentSession = await Auth.currentSession();
        console.log('###check current session', currentSession)
        // if (currentSession) {
        //   // navigate('/dashboard');
        // }
      } catch (error) {
        console.log('No current session found:', error);
      }
    };

    checkToken();
  }, [navigate]);

  if (!firstLoading) {
    return null; // Render nothing if the token has been applied
  }

  return (
    <MainRegion>
      <Grid container className={classes.grid}>
        <img src={LogoWithAppNameColored} alt="iPopHealth" />
        {firstLoading && (
          <LoadingSpinner
            customText={'Login from iClinic...'}
            style={{ width: '100%', paddingTop: 121.89 }}
          />
        )}
        {error != null ? (
          <div style={{ color: '#B7373E' }}>{error}! Please try again.</div>
        ) : null}
        <div className={classes.footer}>
          <Typography className={classes.footerText}>Powered by</Typography>{' '}
          <img src={MdlandLogoColored} alt="MDLand" />
        </div>
      </Grid>
    </MainRegion>
  );
}
