export const normalizePhoneNumberInput = (value: any, previousValue: any) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength <= 3) {
      // 123
      return currentValue;
    }
    if (cvLength <= 7) {
      // 123-456
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    } else {
      // 123-456-7890
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  }
};

export const handlePhoneNumber = (value: any) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (value.length) {
    if (cvLength <= 3) {
      // 123
      return currentValue;
    }
    if (cvLength < 7) {
      // 123-456
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    } else {
      // 123-456-7890
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  }
};

export const formatePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return phoneNumber;

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const maskPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/.(?=.{4})/g, '*');
};

// To check if the value is a 6 digit number string
export const handleVerificationCode = (value: string) => {
  // Remove any non-digit characters
  const currentValue = value.replace(/[^\d]/g, '');

  // Check if the value is a 6-digit number string
  if (currentValue.length <= 6) {
    return currentValue;
  }

  // Return only the first 6 digits if more digits are typed
  return currentValue.slice(0, 6);
};

export function formatPhoneNumber(
  phoneNumber: string,
  format: string = '(XXX) XXX-XXXX' // Default format, can be customized to XXX-XXX-XXXX or XXX.XXX.XXXX
): string | null {
  // Remove any non-numeric characters
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Check if the cleaned phone number is exactly 10 digits
  if (cleanedPhoneNumber.length !== 10) {
    console.error('Phone number must be exactly 10 digits.');
    return phoneNumber; // invalid phone number
  }

  // Extract the components of the phone number
  const areaCode = cleanedPhoneNumber.slice(0, 3);
  const centralOfficeCode = cleanedPhoneNumber.slice(3, 6);
  const lineNumber = cleanedPhoneNumber.slice(6);

  // Replace placeholders in the format string with the actual phone number parts
  let formattedPhoneNumber = format
    .replace('XXX', areaCode)
    .replace('XXX', centralOfficeCode) // Will replace the second XXX found
    .replace('XXXX', lineNumber); // Replace XXXX with the last part

  return formattedPhoneNumber;
}
