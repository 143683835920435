/**
 * For any given users, we say that a user is new if their created date is after the date we set up.
 */

import { User } from '../app.types.generated';

const SETUP_DATE = '2024-09-30';

export function isNewUser(loggedInUser: User): boolean {
  const { createdAt } = loggedInUser;

  return new Date(createdAt) > new Date(SETUP_DATE);
}
