import * as Types from '../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type ToggleMfaMutationVariables = Types.Exact<{
  input: Types.EnableMfaRequest;
}>;


export type ToggleMfaMutation = (
  { __typename: 'Mutation' }
  & { enableMFA: (
    { __typename: 'EnableMFAResponse' }
    & Pick<Types.EnableMfaResponse, 'isSuccess'>
  ) }
);


export const ToggleMfaDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"toggleMFA"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EnableMFARequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enableMFA"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}}]}}]}}]};
export type ToggleMfaMutationFn = Apollo.MutationFunction<ToggleMfaMutation, ToggleMfaMutationVariables>;

/**
 * __useToggleMfaMutation__
 *
 * To run a mutation, you first call `useToggleMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMfaMutation, { data, loading, error }] = useToggleMfaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleMfaMutation(baseOptions?: Apollo.MutationHookOptions<ToggleMfaMutation, ToggleMfaMutationVariables>) {
        return Apollo.useMutation<ToggleMfaMutation, ToggleMfaMutationVariables>(ToggleMfaDocument, baseOptions);
      }
export type ToggleMfaMutationHookResult = ReturnType<typeof useToggleMfaMutation>;
export type ToggleMfaMutationResult = Apollo.MutationResult<ToggleMfaMutation>;
export type ToggleMfaMutationOptions = Apollo.BaseMutationOptions<ToggleMfaMutation, ToggleMfaMutationVariables>;