import {
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { GlobalContext } from '../GlobalContext';
import { StyledCheckbox } from '../StyledCheckbox';
import { useToggleMfaMutation } from './index.generated';

interface MFACheckboxProps {
  userId: string;
  mfaEnabled: boolean;
}

export const MFACheckbox: React.FC<MFACheckboxProps> = ({
  userId,
  mfaEnabled,
}) => {
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  const [toggleMfaMutation, { loading }] = useToggleMfaMutation();
  const classes = useStyles();

  const [checkedMFA, setCheckedMFA] = React.useState<boolean>(mfaEnabled);
  React.useEffect(() => {
    setCheckedMFA(mfaEnabled);
  }, [mfaEnabled]);

  // React.useEffect(() => {
  //   if (loading) {
  //     setToastMessage({
  //       severity: 'success',
  //       snackbarMsg: 'Please wait...',
  //       isOpen: true,
  //     });
  //     setIsOpenToast(true);
  //   }
  // }, [loading, setToastMessage, setIsOpenToast]);

  const handleCheckboxChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setCheckedMFA(checked);

      try {
        await toggleMfaMutation({
          variables: {
            input: {
              userId,
              action: checked ? 'enable' : 'disable',
            },
          },
        });

        setToastMessage({
          severity: 'success',
          snackbarMsg: checked
            ? 'Multi-Factor Authentication Enabled'
            : 'Multi-Factor Authentication Disabled',
          isOpen: true,
        });
        setIsOpenToast(true);
      } catch (error) {
        setToastMessage({
          severity: 'error',
          snackbarMsg: 'Something went wrong. Please try again later.',
          isOpen: true,
        });
        setIsOpenToast(true);
      }
    },
    [checkedMFA, userId, toggleMfaMutation, setToastMessage]
  );

  return (
    <div className={classes.root}>
      <Typography className={classes.inputLabel}>
        Enable Multi-Factor Authentication
      </Typography>

      <label
        htmlFor="mfaCheckbox"
        className={classes.checkboxAndLabelContainer}
      >
        <StyledCheckbox
          id="mfaCheckbox"
          checked={checkedMFA}
          onChange={handleCheckboxChange}
          checkedAll={true}
          className={classes.checkBoxPosition}
        />
        <Typography className={classes.checkboxLabel}>Enable MFA</Typography>
      </label>
      <Typography className={classes.subLabel}>
        Secure your account by enable phone verification to your password.
      </Typography>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  checkBoxPosition: {
    padding: 0,
    '& span.MuiIconButton-label > span, span::before': {
      width: 20,
      height: 20,
    },
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(1),
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontWeight: 600,
    paddingLeft: theme.spacing(3.5),
    paddingTop: 0,
  },
  checkboxAndLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
  },
  checkboxLabel: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.grey[600],
  },
}));
