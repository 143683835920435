import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useFeature } from '../components/FeatureFlag';
import { GlobalContext } from '../components/GlobalContext';
import Auth from '@aws-amplify/auth';
import { SessionTimeoutDialog } from '../components/SessionTimeoutDialog';

// Define types for the context
interface TimerContextType {
  isInactive: boolean;
  resetTimer: () => void;
}

// Create a new React Context with default values
const TimerContext = createContext<TimerContextType>({
  isInactive: false,
  resetTimer: () => {},
});

// Define constants for the warning and logout time
const WARNING_TIME = 29 * 60 * 1000; // 29 minutes
const LOGOUT_TIME = 30 * 60 * 1000; // 30 minutes

// Create a Provider component
export const ApplicationActiveTimerProvider: React.FC = ({ children }) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const warningTimerRef = useRef<NodeJS.Timeout | null>(null);
  const { loggedInUser, setMFADialogState } = useContext(GlobalContext);
  const hasAutoLogout = useFeature('autoLogOut');
  const [isInactive, setIsInactive] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [lessThanOneMinute, setLessThanOneMinute] = useState<boolean>(false);

  // Memoize resetTimer to prevent unnecessary re-renders and stale closures
  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (warningTimerRef.current) {
      clearTimeout(warningTimerRef.current);
    }

    // Set warning timer
    warningTimerRef.current = setTimeout(() => {
      if (hasAutoLogout && loggedInUser) {
        // console.log('App will be inactive in 1 minute');
        setLessThanOneMinute(true);
      }
    }, WARNING_TIME);

    // Set logout timer
    timerRef.current = setTimeout(() => {
      if (hasAutoLogout && loggedInUser) {
        // console.log('App has been inactive for 30 minutes');
        setIsInactive(true);
        setLessThanOneMinute(false);
      }
    }, LOGOUT_TIME);
  }, [hasAutoLogout, loggedInUser]);

  // Update dialog visibility directly based on lessThanOneMinute or isInactive
  useEffect(() => {
    setShowDialog(lessThanOneMinute || isInactive);
  }, [lessThanOneMinute, isInactive]);

  // Initialize timers and event listeners for user activity
  useEffect(() => {
    resetTimer(); // Initialize timer

    // Event listeners for detecting user activity
    const events = ['mousemove', 'mousedown', 'keypress', 'scroll'];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Cleanup function to clear timers and remove event listeners
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (warningTimerRef.current) {
        clearTimeout(warningTimerRef.current);
      }
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [resetTimer]);

  // Provide timer state and reset function to children components
  return (
    <TimerContext.Provider value={{ isInactive, resetTimer }}>
      {children}
      {showDialog && (
        <SessionTimeoutDialog
          lessThanOneMinute={lessThanOneMinute}
          isInactive={isInactive}
          onContinue={() => {
            setShowDialog(false);
            setLessThanOneMinute(false);
            setIsInactive(false);
            resetTimer();
            setMFADialogState({
              showMFADialog: true,
              userId: loggedInUser?.id || '',
              phoneNumber: loggedInUser?.phone || '',
              verifyButtonClicked: false,
              skipForNow: false,
            });
          }}
          onSignIn={() => {
            setShowDialog(false);
            Auth.signOut();
          }}
        />
      )}
    </TimerContext.Provider>
  );
};

// Custom hook to use the TimerContext
export const useApplicationActiveTimer = () => {
  const context = useContext(TimerContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationActiveTimer must be used within an ApplicationActiveTimerProvider'
    );
  }
  return context;
};
