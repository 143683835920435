export const config = {
  api: {
    url:
      process.env.REACT_APP_GRAPHQL_ENDPOINT ||
      `${window.location.protocol}//${window.location.hostname}:4000`,
  },
  versionNumber: process.env.REACT_APP_VERSION_NUMBER,
  iClinic: {
    backendUrl: process.env.REACT_APP_GET_BACKEND_API_KEY_URL,
    iClinicUserUrl: process.env.REACT_APP_GET_ICLINIC_USER_URL,
  },
  aws: {
    cognito: {
      appClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID!,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID!,
      oauthDomain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN!,
      oauthSignInPath: '/auth/callback',
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    },
    region: process.env.REACT_APP_AWS_REGION!,
    s3: {
      key: process.env.REACT_APP_USER_UPLOAD_KEY!,
      userUploadBucket: process.env.REACT_APP_USER_UPLOAD_BUCKET!,
    },
  },
  rollbar: {
    accessToken: '',
    environment: process.env.REACT_APP_ROLLBAR_ENV!,
    devMode: process.env.REACT_APP_ROLLBAR_DEV_MODE === 'true',
  },
  application: {
    adminAppUrl: process.env.REACT_APP_ADMIN_APP_URL!,
    commitHash: process.env.REACT_APP_COMMIT_HASH!,
    version: process.env.REACT_APP_VERSION || 'local',
  },
  debug: {
    appEnv: process.env.REACT_APP_ENV!,
    isStaticApp: process.env.REACT_APP_STATIC_WEB === 'true',
    nodeEnv: process.env.NODE_ENV!,
    logLevel: process.env.REACT_APP_LOG_LEVEL || 'ERROR',
    showDebugComponents: process.env.DEBUG === 'true',
  },
  noLogEnv: {
    env: ['staging', 'uat', 'prod'],
  },
  featureListByAccountId: {
    9000: {
      // 9000: Default
      callAttempts: true,
      accessLockLimitation: true,
      eligibilityURL: true,
      eligibilitySidebar: true,
      eligibilityStatusBadgeInPatientFaceTable: true,
      eligibilityStatusFilter: true,
      eligibilityStatusBadgeColumnInPatientTable: true,
      coordination: true,
      division: true,
      eligibilityCheckButton: true,
      reportSidebar: true,
      reportURL: true,
      autoLogOut: true,
    },

    9001: {
      // 9001: Hudson
      callAttempts: true,
      accessLockLimitation: true,
      eligibilityURL: false,
      eligibilitySidebar: false,
      eligibilityStatusBadgeInPatientFaceTable: false,
      eligibilityStatusFilter: false,
      eligibilityStatusBadgeColumnInPatientTable: false,
      coordination: true,
      division: false,
      eligibilityCheckButton: false,
      reportSidebar: true,
      reportURL: true,
      autoLogOut: true,
      vbcDashboard: true,
    },

    9002: {
      // 9002: Demo site config.
      callAttempts: true,
      accessLockLimitation: true,
      eligibilityURL: true,
      eligibilitySidebar: true,
      eligibilityStatusBadgeInPatientFaceTable: true,
      eligibilityStatusFilter: true,
      eligibilityStatusBadgeColumnInPatientTable: true,
      coordination: true,
      division: true,
      eligibilityCheckButton: true,
      reportSidebar: true,
      reportURL: true,
      autoLogOut: true,
    },

    9003: {
      // 9003: ECap
      callAttempts: false,
      accessLockLimitation: false,
      eligibilityURL: false,
      eligibilitySidebar: false,
      eligibilityStatusBadgeInPatientFaceTable: false,
      eligibilityStatusFilter: false,
      eligibilityStatusBadgeColumnInPatientTable: false,
      coordination: false,
      division: false,
      coveringProvider: false,
      eligibilityCheckButton: false,
      reportSidebar: true,
      reportURL: true,
      autoLogOut: true,
    },
    9004: {
      // Excelsior
      callAttempts: true,
      accessLockLimitation: true,
      eligibilityURL: false,
      eligibilitySidebar: false,
      eligibilityStatusBadgeInPatientFaceTable: false,
      eligibilityStatusFilter: false,
      eligibilityStatusBadgeColumnInPatientTable: false,
      coordination: true,
      division: false,
      eligibilityCheckButton: false,
      reportSidebar: true,
      reportURL: true,
      autoLogOut: true,
      vbcDashboard: true, // Excelsior VBC Dashboard in PROD
    },
    9999: {
      // 9000: Default
      callAttempts: true,
      accessLockLimitation: true,
      eligibilityURL: true,
      eligibilitySidebar: true,
      eligibilityStatusBadgeInPatientFaceTable: true,
      eligibilityStatusFilter: true,
      eligibilityStatusBadgeColumnInPatientTable: true,
      coordination: true,
      division: true,
      eligibilityCheckButton: true,
      reportSidebar: true,
      reportURL: true,
      autoLogOut: true,
    },
  },
  featureListByEnv: {
    uat: {
      vbcDashboard: true,
    },
    prod: {
      vbcDashboard: true,
    },
  },
  mixPanel: {
    enable: false,
    token: process.env.REACT_APP_MIX_PANEL_TOKEN,
    proxy: process.env.REACT_APP_MIX_PANEL_PROXY,
  },
  powerBI: {
    tokenURL: process.env.REACT_APP_POWERBI_TOKEN_URL,
    grantType: process.env.REACT_APP_POWERBI_GRANT_TYPE,
    clientId: process.env.REACT_APP_POWERBI_CLIENT_ID,
    clientSecret: process.env.REACT_APP_POWERBI_CLIENT_SECRET,
  },
} as const;
