import React, {useContext} from 'react';
import {GlobalContext} from '../../components/GlobalContext';
import {Navigate} from 'react-router';
import {LoginView} from './View';
import {CheckIsAdmin} from '../../components/CheckIsAdmin';
import {LoadingSpinner} from "../../components/LoadingSpinner";
import LogoWithAppNameColored from "../../assets/Login/LogoWithAppNameColored.svg";
import {Grid, Typography} from "@material-ui/core";
import MdlandLogoColored from "../../assets/Login/MdlandLogoColored.svg";
import {makeStyles} from "@material-ui/core/styles";
import {MainRegion} from "../../components/MainRegion";

const useStyles = makeStyles((theme) => ({
    grid: {
        paddingTop: 280,
        flexGrow: 1,
        justifyContent: 'center',
    },
    footerText: {
        fontSize: 14,
        paddingRight: theme.spacing(1),
        color: '#434343',
        fontWeight: 400,
        lineHeight: '19px',
    },
    footer: {
        position: 'absolute',
        bottom: 56,
        display: 'flex',
        alignItems: 'center',
    },
}));

const Login: React.FunctionComponent = () => {
    const {loggedInUser, isLoginFromiClinic} = useContext(GlobalContext);
    const classes = useStyles();
    // log in to last user path : location?.state as any
    if (loggedInUser == undefined) {
        if (isLoginFromiClinic) {
            return (
                <MainRegion>
                    <Grid container className={classes.grid}>
                        <img src={LogoWithAppNameColored} alt="iPopHealth"/>
                        <LoadingSpinner
                            customText={'Login from iClinic...'}
                            style={{width: '100%', paddingTop: 121.89}}
                        />
                        <div className={classes.footer}>
                            <Typography className={classes.footerText}>Powered by</Typography>{' '}
                            <img src={MdlandLogoColored} alt="MDLand"/>
                        </div>
                    </Grid>
                </MainRegion>)
        }
        return <LoginView/>;
    }

    const {from} = {
        from: {
            pathname: CheckIsAdmin(loggedInUser) ? '/dashboard' : '/dashboard',
        },
    };
    return <Navigate to={from}/>;
};

export default Login;
