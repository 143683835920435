import { compareStringPipe } from '../../sharePipe/compareStringPipe';
import { UserDefinedRole } from '../../enum/role';

export function CheckIsAdmin(user: any) {
  try {
    return (
      compareStringPipe(UserDefinedRole.MSO_ADMIN, user?.role?.roleName) ||
      compareStringPipe(UserDefinedRole.APPLICATION_ADMIN, user?.role?.roleName)
    );
  } catch (e) {
    return false;
  }
}

export function CheckIsAdvanceUser(user: any) {
  try {
    return compareStringPipe(
      UserDefinedRole.ADVANCE_USER,
      user?.role?.roleName
    );
  } catch (e) {
    return false;
  }
}

export function CheckIsCareManager(user: any) {
  try {
    if (
      compareStringPipe(UserDefinedRole.ADMIN, user?.role?.roleName) || // replace admin to care manager
      compareStringPipe(UserDefinedRole.CARE_MANAGER, user?.role?.roleName)
    ) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export function CheckIsCoordinator(user: any) {
  try {
    if (compareStringPipe(UserDefinedRole.COORDINATOR, user?.role?.roleName)) {
      return true;
    }
  } catch (error) {
    return false;
  }
}
export function CheckIsHudsonClinician(user: any) {
  if (!user) {
    return false;
  } else {
    if (user.accountId === '9001') {
      // Hudson account id
      try {
        if (
          compareStringPipe(
            UserDefinedRole.HUDSON_CLINICIAN,
            user?.role?.roleName
          )
        ) {
          return true;
        }
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }
}

export function CheckIsDemoSiteProfessionalClinician(user: any) {
  if (!user) {
    return false;
  } else {
    if (user.accountId === '9999') {
      // Hudson account id
      try {
        if (
          compareStringPipe(
            UserDefinedRole.HUDSON_CLINICIAN,
            user?.role?.roleName
          )
        ) {
          return true;
        }
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }
}

export function CheckIsHudsonAccount(user: any) {
  if (!user) {
    return false;
  }

  if (user.accountId === '9001') {
    // Hudson account id
    return true;
  } else {
    return false;
  }
}

export function CheckIsDemoSiteProfessionalAccount(user: any) {
  if (!user) {
    return false;
  }

  if (user.accountId === '9999') {
    // Hudson account id
    return true;
  } else {
    return false;
  }
}

export function CheckIsExcelsiorAccount(user: any) {
  if (!user) {
    return false;
  }

  if (user.accountId === '9004') {
    // Excelsior account id
    return true;
  } else {
    return false;
  }
}
