import React from 'react';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Switch, { SwitchProps } from '@material-ui/core/Switch';

interface Props extends SwitchProps {
  checked: boolean;
  name: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 44,
      height: 24,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: 'rgba(12, 119, 216, 1)',
          borderColor: 'rgba(12, 119, 216, 1)',
        },
      },
      '&$disabled': {
        color: theme.palette.grey[400],
      },
    },
    thumb: {
      width: 20,
      height: 20,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },
    track: {
      borderRadius: 12,
      opacity: 1,
      backgroundColor: '#D1D1D1',
      '$switchBase$checked + &': {
        backgroundColor: 'rgba(12, 119, 216, 1)',
        borderColor: 'rgba(12, 119, 216, 1)',
      },
      '$switchBase$disabled + &': {
        opacity: 0.5,
        backgroundColor: theme.palette.grey[300],
      },
    },
    checked: {},
    disabled: {},
  })
)(Switch);

export const CustomizedSwitch: React.FC<Props> = ({
  checked,
  name,
  handleChange,
  ...rest
}) => {
  return (
    <AntSwitch
      checked={checked}
      onChange={handleChange}
      name={name}
      onClick={(e) => e.stopPropagation()} // Prevent event bubbling if necessary
      {...rest}
    />
  );
};
