import * as Types from '../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GlobalContextQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GlobalContextQuery = (
  { __typename: 'Query' }
  & { me?: Types.Maybe<(
    { __typename: 'User' }
    & Pick<Types.User, 'avatarUrl' | 'accountId' | 'firstName' | 'id' | 'lastName' | 'email' | 'title' | 'phone' | 'npi' | 'clinicId' | 'phoneVerified' | 'mfaEnabled' | 'createdAt'>
    & { role?: Types.Maybe<(
      { __typename: 'Role' }
      & Pick<Types.Role, 'id' | 'roleName'>
      & { urls?: Types.Maybe<Array<(
        { __typename: 'Url' }
        & Pick<Types.Url, 'url' | 'urlName'>
      )>> }
    )> }
  )> }
);


export const GlobalContextDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"globalContext"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"avatarUrl"}},{"kind":"Field","name":{"kind":"Name","value":"accountId"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"role"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"roleName"}},{"kind":"Field","name":{"kind":"Name","value":"urls"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"urlName"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"npi"}},{"kind":"Field","name":{"kind":"Name","value":"clinicId"}},{"kind":"Field","name":{"kind":"Name","value":"phoneVerified"}},{"kind":"Field","name":{"kind":"Name","value":"mfaEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]}}]};

/**
 * __useGlobalContextQuery__
 *
 * To run a query within a React component, call `useGlobalContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalContextQuery(baseOptions?: Apollo.QueryHookOptions<GlobalContextQuery, GlobalContextQueryVariables>) {
        return Apollo.useQuery<GlobalContextQuery, GlobalContextQueryVariables>(GlobalContextDocument, baseOptions);
      }
export function useGlobalContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalContextQuery, GlobalContextQueryVariables>) {
          return Apollo.useLazyQuery<GlobalContextQuery, GlobalContextQueryVariables>(GlobalContextDocument, baseOptions);
        }
export type GlobalContextQueryHookResult = ReturnType<typeof useGlobalContextQuery>;
export type GlobalContextLazyQueryHookResult = ReturnType<typeof useGlobalContextLazyQuery>;
export type GlobalContextQueryResult = Apollo.QueryResult<GlobalContextQuery, GlobalContextQueryVariables>;
export function refetchGlobalContextQuery(variables?: GlobalContextQueryVariables) {
      return { query: GlobalContextDocument, variables: variables }
    }