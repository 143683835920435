import { Button, OutlinedInput, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  formatePhoneNumber,
  handlePhoneNumber,
} from '../../../../sharePipe/normalizePhoneNumberInput';
import { useOutlinedInputStyles } from '../../../../styles/outlineInputStyle';
import { useMFAStyles } from '../MFA';
import { MFASteps } from '../../View';
import { theme } from '../../../../themes/theme';
import { GlobalContext } from '../../../../components/GlobalContext';
import { useSendCodeMutation } from '../../../../components/MFADialog/index.generated';
import { isNewUser } from '../../../../sharePipe/isNewUser';
import { User } from '../../../../app.types.generated';

interface VerifyPhoneNumberFormProps {
  setSteps: React.Dispatch<React.SetStateAction<MFASteps>>;
  steps?: MFASteps;
  inDialog?: boolean;
  editPage?: boolean;
}

type PhoneNumber = {
  phoneNumber: string;
};

export const VerifyPhoneNumberForm: React.FC<VerifyPhoneNumberFormProps> = ({
  steps,
  setSteps,
  inDialog = false,
  editPage = false,
}) => {
  const {
    setMFADialogState,
    MFADialogState,
    setToastMessage,
    setIsOpenToast,
    loggedInUser,
  } = React.useContext(GlobalContext);
  const classes = useMFAStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [phoneNumber, setPhoneNumber] = React.useState('');

  const [sendCodeMutation] = useSendCodeMutation();

  const {
    control,
    handleSubmit,
    getValues,
    register,
    errors,
  } = useForm<PhoneNumber>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      phoneNumber: formatePhoneNumber(phoneNumber),
    },
  });

  const handlePhoneNumberChange = (e: any) => {
    setPhoneNumber(handlePhoneNumber(e.target.value));
  };

  const sendCode = async () => {
    const digits = phoneNumber.replace(/-/g, '');
    try {
      sendCodeMutation({
        variables: {
          SendCodeRequest: {
            phone: digits,
            userId: inDialog ? MFADialogState.userId : steps!.userId!,
          },
        },
      });
      setSteps({
        step: 1,
        title: 'Verification Code',
        phone: digits,
        userId: inDialog ? MFADialogState.userId : steps!.userId!,
      });
      setToastMessage({
        severity: 'success',
        snackbarMsg: 'A verification code has been sent to you',
        isOpen: true,
      });
      setIsOpenToast(true);
    } catch (error) {
      setToastMessage({
        snackbarMsg: `Something went wrong. Please try again!`,
        severity: 'error',
        isOpen: true,
      });
      setIsOpenToast(true);
    }
  };

  return (
    <div role="VerifyPhoneNumberForm" className={classes.root}>
      <form onSubmit={handleSubmit(sendCode)} className={classes.root}>
        <Paper
          className={classes.resetPwCard}
          elevation={inDialog ? 0 : 8}
          style={{
            padding: inDialog ? 0 : theme.spacing(5, 4, 5, 4),
            gap: inDialog ? theme.spacing(2) : theme.spacing(3),
          }}
        >
          <Typography className={classes.instructions}>
            For your security and to comply with HIPAA regulations and to meet
            HIPAA Compliance, we wants to make sure it's really you. We will
            send a text message with a 6-digit verification code. Standard rates
            apply.
          </Typography>
          <div>
            <Typography className={classes.inputLabel}>Phone Number</Typography>
            <OutlinedInput
              fullWidth
              inputProps={{
                required: true,
              }}
              name={'phone'}
              defaultValue=""
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              error={
                phoneNumber?.length > 0 &&
                phoneNumber.match(/^[2-9][0-9]{2}-[0-9]{3}-[0-9]{4}$/i) == null
              }
              className={classes.nameInput}
              placeholder="Enter Phone Number(s)"
              classes={outlinedInputClasses}
            />
          </div>

          <Button
            type="submit"
            color="primary"
            className={classes.submitButton}
            disabled={
              phoneNumber?.length > 0 &&
              phoneNumber.match(/^[2-9][0-9]{2}-[0-9]{3}-[0-9]{4}$/i) == null
            }
          >
            Send Code
          </Button>

          {inDialog && !editPage ? (
            !isNewUser(loggedInUser as User) ? (
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  variant="text"
                  color="primary"
                  className={classes.textBtnWithBottomLine}
                  style={{
                    textAlign: 'center',
                  }}
                  onClick={() => {
                    setMFADialogState({
                      showMFADialog: false,
                      userId: '',
                      phoneNumber: '',
                      verifyButtonClicked: false,
                      skipForNow: true,
                    });
                  }}
                >
                  Skip for Now
                </Button>
              </div>
            ) : null
          ) : null}
        </Paper>
      </form>
    </div>
  );
};
