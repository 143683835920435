import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { CustomizedSwitch } from '../../../../../components/Switch';
import { GlobalContext } from '../../../../../components/GlobalContext';
import { useToggleMfaMutation } from '../../../../../components/MFACheckbox/index.generated';

interface MFASwitchProps {
  userId: string;
  name: string;
  mfaEnabled: boolean;
}

export const MFASwitch: React.FC<MFASwitchProps> = ({
  userId,
  name,
  mfaEnabled,
}) => {
  const classes = useStyles();
  const { setToastMessage, setIsOpenToast } = React.useContext(GlobalContext);
  const [toggleMfaMutation] = useToggleMfaMutation();

  const [switchState, setSwitchState] = React.useState<Record<string, boolean>>(
    {
      [name]: mfaEnabled,
    }
  );

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    // Optimistically update the switch state
    setSwitchState({ [name]: checked });

    try {
      await toggleMfaMutation({
        variables: {
          input: {
            userId,
            action: checked ? 'enable' : 'disable',
          },
        },
      });

      setToastMessage({
        severity: 'success',
        snackbarMsg: checked
          ? 'Multi-Factor Authentication Enabled'
          : 'Multi-Factor Authentication Disabled',
        isOpen: true,
      });
    } catch (error) {
      // Revert the switch state if the mutation fails
      setSwitchState({ [name]: !checked });

      setToastMessage({
        severity: 'error',
        snackbarMsg: 'Something went wrong. Please try again later.',
        isOpen: true,
      });
    } finally {
      setIsOpenToast(true);
    }
  };

  return (
    <div id="MFASwitch" className={classes.root}>
      <CustomizedSwitch
        checked={switchState[name]}
        name={name}
        handleChange={handleChange}
      />
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));
