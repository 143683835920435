// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { GlobalContext } from '../../components/GlobalContext';
import { LoginForm } from './components/LoginForm/LoginForm';
import LoginBackground from '../../assets/Login/LoginBackground.jpg';
import LogoWithAppNameWhite from '../../assets/Login/LogoWithAppNameWhite.svg';
import MdlandLogo from '../../assets/Login/MdlandLogo.svg';
import { ResetPasswordForm } from './components/ResetPasswordForm/ResetPasswordForm';
import { SuccessSnackbar } from './components/SuccessSnackbar/SuccessSnackbar';
import { NewPasswordForm } from './components/NewPasswordForm/NewPasswordForm';
import { config } from '../../config';
import { VerifyPhoneNumberForm } from './components/VerifyPhoneNumberForm';
import { VerificationCodeForm } from './components/VerificationCodeForm';
import { MFA } from './components/MFA';
import { Toaster } from '../../components/Toaster';
import { SMSMFA } from './components/SMSMFA';

export type PropTypes = {};
const versionNumber = config.versionNumber;

const useStyles = makeStyles((theme) => ({
  root: {
    width: document.width,
    height: document.height,
    display: 'flex',
  },
  leftContainer: {
    minHeight: '100vh',
    width: '56.39%',
    backgroundImage: `url(${LoginBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightContainer: {
    width: '43.61%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  title: {
    textAlign: 'center',
    fontSize: theme.spacing(6),
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: theme.spacing(9.75),
    paddingBottom: theme.spacing(4.25),
  },
  leftFooter: {
    position: 'absolute',
    bottom: 56,
    display: 'flex',
    alignItems: 'center',
  },
  leftFooterText: {
    fontSize: 14,
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  rightFooterText: {
    paddingTop: theme.spacing(4),
    fontSize: 12,
    color: theme.palette.grey[500],
  },
}));

export const Forms = {
  Login: 'Login',
  ResetPw: 'ResetPw',
  NewPw: 'NewPw',
  MFA: 'MFA',
  VerifyPhoneNumber: 'VerifyPhoneNumber',
  VerificationCode: 'VerificationCode',
  SMS_MFA: 'SMS_MFA',
};

export type MFASteps = {
  step: number;
  title: string;
  phone: string;
  userId?: string;
  snackbar?: boolean;
  snackbarMessage?: string;
};

export const LoginView: React.FC<PropTypes> = (props: PropTypes) => {
  const classes = useStyles();
  const {
    loggedInUser,
    isOpenToast,
    toastMessage,
    setIsOpenToast,
  } = useContext(GlobalContext);
  const location = useLocation();
  const [renderForm, setRenderForm] = useState<string>(Forms.Login);
  const [cognitoUser, setCognitoUser] = useState<object>({});
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [mfaSteps, setMfaSteps] = useState<MFASteps>({
    step: 0,
    title: 'Verify Phone Number',
    phone: loggedInUser?.phone || '',
  });
  const [snackbarMessage, setSnackbarMessage] = useState<string>(
    'Your password has been updated!'
  );
  const [codeDestination, setCodeDestination] = useState<string>('');

  React.useEffect(() => {
    if (mfaSteps.snackbar) {
      setSnackbar(true);
      setSnackbarMessage(mfaSteps.snackbarMessage);
    }
  }, [mfaSteps]);

  React.useEffect(() => {
    if (mfaSteps.step === 2) {
      setRenderForm(Forms.NewPw);
    }
  }, [mfaSteps.step]);

  React.useEffect(() => {
    if (cognitoUser.challengeParam === 'NEW_PASSWORD_REQUIRED') {
      const userId =
        cognitoUser.challengeParam.userAttributes['custom:mdlandUserId'];
      setMfaSteps({ ...mfaSteps, userId });
    }

    if (cognitoUser.challengeName === 'SMS_MFA') {
      setCodeDestination(cognitoUser.challengeParam.CODE_DELIVERY_DESTINATION);
    }
  }, [cognitoUser]);

  const getYear = () => {
    try {
      return new Date().getFullYear();
    } catch (e: any) {
      return '2024';
    }
  };

  const { from } = (location?.state as any) || {
    from: { pathname: '/dashboard' },
  };

  if (loggedInUser) {
    return <Navigate to={from} />;
  }

  return (
    <div className={classes.root}>
      <SuccessSnackbar
        open={snackbar}
        setOpen={setSnackbar}
        message={snackbarMessage}
      />
      <div className={classes.leftContainer}>
        <img src={LogoWithAppNameWhite} alt="iPopHealth" />
        <div className={classes.leftFooter}>
          <Typography className={classes.leftFooterText}>Powered by</Typography>
          <img src={MdlandLogo} alt="MDLand" />
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Typography className={classes.title}>
          {(renderForm === Forms.Login || renderForm === Forms.SMS_MFA) &&
            'Sign in'}
          {renderForm === Forms.ResetPw && 'Reset your password'}
          {renderForm === Forms.NewPw && 'Set new password'}
          {renderForm === Forms.MFA && mfaSteps.title}
        </Typography>
        {renderForm === Forms.Login && (
          <LoginForm
            setRenderForm={setRenderForm}
            setCognitoUser={setCognitoUser}
          />
        )}
        {renderForm === Forms.ResetPw && (
          <ResetPasswordForm
            setRenderForm={setRenderForm}
            setSnackbar={setSnackbar}
          />
        )}
        {renderForm === Forms.NewPw && (
          <NewPasswordForm
            setRenderForm={setRenderForm}
            cognitoUser={cognitoUser}
          />
        )}
        {renderForm === Forms.MFA && (
          <MFA steps={mfaSteps} setSteps={setMfaSteps} />
        )}
        {renderForm === Forms.SMS_MFA && (
          <SMSMFA
            codeDestination={codeDestination}
            setRenderForm={setRenderForm}
            cognitoUser={cognitoUser}
          />
        )}
        <Typography className={classes.rightFooterText}>
          Copyright <span>&copy;</span> MDLand {getYear()}
        </Typography>
        <Typography className={classes.rightFooterText}>
          {versionNumber}
        </Typography>
      </div>
    </div>
  );
};
