import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@material-ui/core';
import { useMFAStyles } from '../MFA';

const DEFAULT_COUNTDOWN_SECONDS = 30;

interface ResendCodeButtonProps {
  resendCode: () => void;
}

export const ResendCodeButton: React.FC<ResendCodeButtonProps> = ({
  resendCode,
}) => {
  const classes = useMFAStyles();

  // State to hold the remaining seconds for the countdown
  const [secondsRemaining, setSecondsRemaining] = useState<number>(
    DEFAULT_COUNTDOWN_SECONDS
  );

  // useRef to store the interval ID so it persists across renders
  const intervalRef = useRef<number | null>(null);

  // useEffect to handle the countdown logic
  useEffect(() => {
    // If there's time remaining, set up the interval
    if (secondsRemaining > 0) {
      intervalRef.current = window.setInterval(() => {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      // If the countdown is over, clear the interval
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    // Cleanup function to clear the interval when the component unmounts or secondsRemaining changes
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [secondsRemaining]);

  // Function to handle the resend code action
  const handleResendCode = async () => {
    // Start the countdown
    setSecondsRemaining(DEFAULT_COUNTDOWN_SECONDS);

    await resendCode();
  };

  return (
    <Button
      disabled={secondsRemaining > 0}
      variant="text"
      color="primary"
      className={classes.textBtnWithBottomLine}
      onClick={handleResendCode}
    >
      {secondsRemaining > 0
        ? `Resend Code in ${secondsRemaining} Seconds`
        : 'Resend Code'}
    </Button>
  );
};
