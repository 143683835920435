import React, { useCallback, useLayoutEffect } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
import ReportContainer from './scenes/Report';
import ThemeProvider from './themes/ThemeProvider';
import GlobalContextContainer from './components/GlobalContext';

import './bootstrap';

// Routes
import { AuthenticatedRoute } from './components/Authenticator';
import { HomePage } from './scenes/HomePage';

import Login from './scenes/Login';
import { MyApolloProvider } from './constants/apolloClient';
import ErrorBoundary from './components/ErrorBoundary';
import MainLayout from './layouts/MainLayout';
import { PCP } from './scenes/PCP';
import { AuthenticatedRouteWithPermission } from './components/AuthenticatorWithPermission';
import Auth from '@aws-amplify/auth';
import { SendSmsDialog } from './components/ErrorDialog/DiabledErrorDialog';
import { ErrorView } from './components/ErrorBoundary/View';
import { DashBoardHome } from './scenes/DashBoard/Home';
import { PanelsListLayoutBuilder } from './scenes/Panels/LayoutBuilder';
import { RoutedCreatePanel } from './scenes/Panels/CreatePanel/Routed';
import { PanelSummaryContainer } from './scenes/Panels/ViewPanels/PanelSummary/Container';
import { EngagementLogContainer } from './scenes/Panels/ViewPanels/EngagementLog/Container';
import { ProviderPatientList } from './scenes/PCP/ProviderPatientList';
import { PanelDetail } from './scenes/PCP/PanelDetail';
import { AllCareGapsView } from './scenes/CareGaps/AllCareGaps';
import { Dashboard } from './scenes/Users/MsoAdmin/Dashboard';
import { NewUsers } from './scenes/Users/MsoAdmin/NewUsers';
import { EditUser } from './scenes/Users/MsoAdmin/EditUsers';
import { ViewProfile } from './scenes/ViewProfile';
import { MyProfileHome } from './scenes/MyProfile/Home';
import { MyProfileChangePassWordView } from './scenes/MyProfile/Home/ChangePassWordFormView';
import { SettingHomeView } from './scenes/Settings';
import { IClinicLogin } from './scenes/Iclinic/Login/View';
import { PayerFileHome } from './scenes/Integrations/PayerFiles/PayerFileHome';
import { FileNewPatientListView } from './scenes/Integrations/PayerFiles/Files/FileDetailPage/NewPatientList';
import { FileNewPayerListView } from './scenes/Integrations/PayerFiles/Files/FileDetailPage/NewPayerList';
import { PayerCareGapView } from './scenes/Integrations/PayerFiles/Files/PayerCareGap/PayerCareGap';
import { TestWindowPost } from './scenes/Iclinic/test';
import { PayerFiles } from './scenes/Integrations/PayerFiles/PayerId/subPage/PayerFiles/View';
import { PayerFileCareGap } from './scenes/Integrations/PayerFiles/PayerId/subPage/PayerFileCareGap/View';
import { EditPanelView } from './scenes/Panels/EditPanel/View';
import { PayerMostRecentFileRouting } from './scenes/Integrations/PayerFiles/PayerId/subPage/PayerMostRecentFileReRoutePage';
import { PayerPayerFileNewPatientPage } from './scenes/Integrations/PayerFiles/PayerId/subPage/PayerPayerFileNewPatientPage';
import { DashBoardView } from './scenes/Eligibility/Dashboard/View';
import { EligibilityPatientListView } from './scenes/Eligibility/PatientList';
import { PatientPanelProvider } from './scenes/Panels/LayoutBuilder/PatientPanelTabContext';
import { EligibilityEngagementLogContainer } from './scenes/Eligibility/PatientList/components/PatientEngagement/SmsBatchLog/Container';
import { FlagsProvider } from './components/FeatureFlag';
import { PanelSummaryContextContainer } from './scenes/Panels/ViewPanels/PanelSummary/components/PanelSummaryContext/PanelSummaryContext';
import { CareGapProvider } from './scenes/CareGaps/Home/tabs/Population/context';
import { Provider } from 'react-redux';
import store from './store';
import { ApplicationActiveTimerProvider } from './hooks/useApplicationActiveTimer';

const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function ErrorNotifier({ children }: any) {
  const [doShowError, setDoShowError] = React.useState(false);
  const [errorType, SetErrorType] = React.useState('');
  const showError = useCallback((inputErrorType: string) => {
    setDoShowError(true);
    SetErrorType(inputErrorType);
  }, []);
  return (
    <>
      {doShowError
        ? errorType == 'DISABLED_USER' && (
            <SendSmsDialog
              onClose={() => {
                setDoShowError(false);
                Auth.signOut();
              }}
            />
          )
        : null}
      {children(showError)}
    </>
  );
}

export default function AppRouter() {
  return (
    <ErrorBoundary>
      <ClearBrowserCacheBoundary auto fallback="Loading" duration={60000}>
        <Provider store={store}>
          <ThemeProvider>
            <ErrorNotifier>
              {(showError: any) => (
                <MyApolloProvider showError={showError}>
                  <BrowserRouter>
                    <Wrapper>
                      <GlobalContextContainer>
                        <FlagsProvider>
                          <ApplicationActiveTimerProvider>
                            <Routes>
                              <Route
                                path="/home"
                                element={
                                  <AuthenticatedRoute
                                    element={<HomePage />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route path="/login" element={<Login />} />
                              {/*profile*/}
                              <Route
                                path="/profile"
                                element={
                                  <AuthenticatedRoute
                                    element={<ViewProfile />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/profile/edit"
                                element={
                                  <AuthenticatedRoute
                                    element={<MyProfileHome selectedTab={0} />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/profile/change_password"
                                element={
                                  <AuthenticatedRoute
                                    element={
                                      <MyProfileChangePassWordView
                                        selectedTab={1}
                                      />
                                    }
                                    layout={MainLayout}
                                  />
                                }
                              />

                              {/*dashboard*/}
                              <Route
                                path="/dashboard/*"
                                element={
                                  <AuthenticatedRoute
                                    element={<DashBoardHome />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              {/*panels*/}
                              <Route
                                path="/panels"
                                element={
                                  <AuthenticatedRoute
                                    element={
                                      <PatientPanelProvider>
                                        <PanelsListLayoutBuilder />
                                      </PatientPanelProvider>
                                    }
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/panels/create"
                                element={
                                  <AuthenticatedRoute
                                    element={<RoutedCreatePanel />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/panels/listing"
                                element={
                                  <AuthenticatedRoute
                                    element={<PanelsListLayoutBuilder />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/panels/:id"
                                element={
                                  <AuthenticatedRoute
                                    element={<Navigate to="summary" />}
                                  />
                                }
                              />
                              <Route
                                path="/panels/:id/editPanel"
                                element={
                                  <AuthenticatedRoute
                                    element={<EditPanelView />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/panels/:id/:type"
                                element={
                                  <AuthenticatedRoute
                                    element={
                                      <PanelSummaryContextContainer>
                                        <PanelSummaryContainer />
                                      </PanelSummaryContextContainer>
                                    }
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/panels/:id/engagement/:batchId/:status"
                                element={
                                  <AuthenticatedRoute
                                    element={
                                      <EngagementLogContainer
                                        rootLink={'panels'}
                                      />
                                    }
                                    layout={MainLayout}
                                  />
                                }
                              />
                              {/*payerFiles*/}
                              <Route
                                path="/payerFiles"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<PayerFileHome tab={0} />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/payerID"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<PayerFileHome tab={1} />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/payerFiles/:id"
                                element={<Navigate to="summary" />}
                              />
                              <Route
                                path="/payerFiles/:id/summary"
                                element={
                                  <AuthenticatedRoute
                                    element={<FileNewPayerListView />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/payerID/:id"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<PayerFiles />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/payerID/:payerId/:fileId"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<PayerMostRecentFileRouting />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/payerID/:payerId/:fileId/newPatient"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<PayerPayerFileNewPatientPage />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/payerFiles/:id/careGap"
                                element={
                                  <AuthenticatedRoute
                                    element={<PayerCareGapView />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/payerID/:payerId/:id/careGap"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<PayerFileCareGap />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/payerFiles/newPatients/:fileId"
                                element={
                                  <AuthenticatedRoute
                                    element={<FileNewPatientListView />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              {/*Eligibility*/}
                              <Route
                                path="/eligibility"
                                element={
                                  <AuthenticatedRoute
                                    element={<DashBoardView />}
                                    layout={MainLayout}
                                    featureName={'eligibilityURL'}
                                  />
                                }
                              />
                              <Route
                                path="/eligibility/patients/:type"
                                element={
                                  <AuthenticatedRoute
                                    element={<EligibilityPatientListView />}
                                    layout={MainLayout}
                                    featureName={'eligibilityURL'}
                                  />
                                }
                              />
                              <Route
                                path="/eligibility/patients/engagement/log/:batchId/:status"
                                element={
                                  <AuthenticatedRoute
                                    element={
                                      <EligibilityEngagementLogContainer />
                                    }
                                    layout={MainLayout}
                                    featureName={'eligibilityURL'}
                                  />
                                }
                              />

                              {/*pcp*/}
                              <Route
                                path="/pcp"
                                element={
                                  <AuthenticatedRoute
                                    element={<PCP />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/pcp/:providerId"
                                element={
                                  <AuthenticatedRoute
                                    element={
                                      <CareGapProvider>
                                        <ProviderPatientList />
                                      </CareGapProvider>
                                    }
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/pcp/:providerId/:id"
                                element={
                                  <AuthenticatedRoute
                                    element={<PanelDetail />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/pcp/:providerId/:id/:type"
                                element={
                                  <AuthenticatedRoute
                                    element={<PanelDetail />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              <Route
                                path="/pcp/:providerId/:id/engagement/:batchId/:status"
                                element={
                                  <AuthenticatedRoute
                                    element={
                                      <EngagementLogContainer
                                        rootLink={'PCP'}
                                      />
                                    }
                                    layout={MainLayout}
                                  />
                                }
                              />
                              {/*caregap*/}
                              <Route
                                path="/care_gaps/*"
                                element={
                                  <AuthenticatedRoute
                                    element={<AllCareGapsView />}
                                    layout={MainLayout}
                                  />
                                }
                              />
                              {/*user*/}
                              <Route
                                path="/users/all"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<Dashboard />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/users/roles"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<Dashboard />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/users/clinicsAndDivisions"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<Dashboard />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/users/create"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<NewUsers />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/users/:id/update"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<EditUser />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/settings/sms"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={
                                      <SettingHomeView selectedTab={0} />
                                    }
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/report/*"
                                element={
                                  <AuthenticatedRouteWithPermission
                                    element={<ReportContainer />}
                                    layout={MainLayout}
                                    checkAdmin={true}
                                  />
                                }
                              />
                              <Route
                                path="/iclinic/login"
                                element={<IClinicLogin />}
                              />
                              <Route
                                path="/iclinic/testingWindowSentMessage"
                                element={<TestWindowPost />}
                              />
                              <Route
                                path="/"
                                element={
                                  <AuthenticatedRoute
                                    element={<Navigate to="dashboard" />}
                                  />
                                }
                              />
                              <Route
                                path={'*'}
                                element={
                                  <ErrorView
                                    error={new Error('Page Not Found')}
                                    resetErrorBoundary={() => {
                                      window.location.replace('/dashboard');
                                    }}
                                  />
                                }
                              />
                            </Routes>
                          </ApplicationActiveTimerProvider>
                        </FlagsProvider>
                      </GlobalContextContainer>
                    </Wrapper>
                  </BrowserRouter>
                </MyApolloProvider>
              )}
            </ErrorNotifier>
          </ThemeProvider>
        </Provider>
      </ClearBrowserCacheBoundary>
    </ErrorBoundary>
  );
}
