import * as Types from '../../app.types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type SendCodeMutationVariables = Types.Exact<{
  SendCodeRequest: Types.SendCodeRequest;
}>;


export type SendCodeMutation = (
  { __typename: 'Mutation' }
  & { sendCode: (
    { __typename: 'SendCodeResponse' }
    & Pick<Types.SendCodeResponse, 'isSuccess'>
  ) }
);

export type VerifyCodeQueryVariables = Types.Exact<{
  VerifyCodeRequest: Types.VerifyCodeRequest;
}>;


export type VerifyCodeQuery = (
  { __typename: 'Query' }
  & { verifyCode?: Types.Maybe<(
    { __typename: 'VerifyCodeResponse' }
    & Pick<Types.VerifyCodeResponse, 'isSuccess' | 'description'>
  )> }
);


export const SendCodeDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sendCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"SendCodeRequest"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SendCodeRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"SendCodeRequest"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}}]}}]}}]};
export type SendCodeMutationFn = Apollo.MutationFunction<SendCodeMutation, SendCodeMutationVariables>;

/**
 * __useSendCodeMutation__
 *
 * To run a mutation, you first call `useSendCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCodeMutation, { data, loading, error }] = useSendCodeMutation({
 *   variables: {
 *      SendCodeRequest: // value for 'SendCodeRequest'
 *   },
 * });
 */
export function useSendCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendCodeMutation, SendCodeMutationVariables>) {
        return Apollo.useMutation<SendCodeMutation, SendCodeMutationVariables>(SendCodeDocument, baseOptions);
      }
export type SendCodeMutationHookResult = ReturnType<typeof useSendCodeMutation>;
export type SendCodeMutationResult = Apollo.MutationResult<SendCodeMutation>;
export type SendCodeMutationOptions = Apollo.BaseMutationOptions<SendCodeMutation, SendCodeMutationVariables>;
export const VerifyCodeDocument: DocumentNode = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"verifyCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"VerifyCodeRequest"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VerifyCodeRequest"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"verifyCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"VerifyCodeRequest"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isSuccess"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]}}]};

/**
 * __useVerifyCodeQuery__
 *
 * To run a query within a React component, call `useVerifyCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyCodeQuery({
 *   variables: {
 *      VerifyCodeRequest: // value for 'VerifyCodeRequest'
 *   },
 * });
 */
export function useVerifyCodeQuery(baseOptions: Apollo.QueryHookOptions<VerifyCodeQuery, VerifyCodeQueryVariables>) {
        return Apollo.useQuery<VerifyCodeQuery, VerifyCodeQueryVariables>(VerifyCodeDocument, baseOptions);
      }
export function useVerifyCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyCodeQuery, VerifyCodeQueryVariables>) {
          return Apollo.useLazyQuery<VerifyCodeQuery, VerifyCodeQueryVariables>(VerifyCodeDocument, baseOptions);
        }
export type VerifyCodeQueryHookResult = ReturnType<typeof useVerifyCodeQuery>;
export type VerifyCodeLazyQueryHookResult = ReturnType<typeof useVerifyCodeLazyQuery>;
export type VerifyCodeQueryResult = Apollo.QueryResult<VerifyCodeQuery, VerifyCodeQueryVariables>;
export function refetchVerifyCodeQuery(variables?: VerifyCodeQueryVariables) {
      return { query: VerifyCodeDocument, variables: variables }
    }