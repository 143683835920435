import { Button, OutlinedInput, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useOutlinedInputStyles } from '../../../../styles/outlineInputStyle';
import {
  handleVerificationCode,
  maskPhoneNumber,
} from '../../../../sharePipe/normalizePhoneNumberInput';
import { NavigateNextIcon } from '../../../../assets/Icons/CareGaps';
import { useMFAStyles } from '../MFA';
import { MFASteps } from '../../View';
import { theme } from '../../../../themes/theme';
import { GlobalContext } from '../../../../components/GlobalContext';
import {
  useSendCodeMutation,
  useVerifyCodeLazyQuery,
} from '../../../../components/MFADialog/index.generated';
import { ResendCodeButton } from '../ResendCodeTextButton';

interface VerificationCodeFormProps {
  setSteps: React.Dispatch<React.SetStateAction<MFASteps>>;
  inDialog?: boolean;
  steps: MFASteps;
  editPage?: boolean;
}

type VerificationCode = {
  verificationCode: string;
};

type verificationCodeErrorType = {
  error: boolean;
  message: string;
};

enum VerificationDescriptionCode {
  UNKNOWN = 'UNKNOWN',
  WRONG_CODE = 'WRONG_CODE',
  EXPIRED_CODE = 'EXPIRED_CODE',
  OK = 'OK',
}

export const VerificationCodeForm: React.FC<VerificationCodeFormProps> = ({
  setSteps,
  inDialog = false,
  steps,
  editPage = false,
}) => {
  const {
    setMFADialogState,
    loggedInUser,
    setToastMessage,
    setIsOpenToast,
    reloadUser,
    MFADialogState,
  } = React.useContext(GlobalContext);
  const [verificationCodeError, setVerificationCodeError] = React.useState({
    error: false,
    message: '',
  } as verificationCodeErrorType);
  const classes = useMFAStyles();
  const color = verificationCodeError.error ? theme.palette.error.main : '';
  const outlinedInputClasses = useOutlinedInputStyles(color);
  // const outlinedInputClasses = useOutlinedInputStyles(
  //   verificationCodeError.error
  //     ? theme.palette.error.main
  //     : `${theme.palette.primary.main}`
  // );

  const [verificationCode, setVerificationCode] = React.useState('');

  const {
    control,
    handleSubmit,
    getValues,
    register,
    errors,
  } = useForm<VerificationCode>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      verificationCode: '',
    },
  });
  const [sendCodeMutation] = useSendCodeMutation();
  const [verifyCode, verifyCodeResponse] = useVerifyCodeLazyQuery();

  const handleVerificationCodeChange = (e: any) => {
    setVerificationCode(handleVerificationCode(e.target.value));
  };

  const [triggerVerification, setTriggerVerification] = React.useState(false);
  React.useEffect(() => {
    const verify = async () => {
      if (triggerVerification) {
        try {
          await verifyCode({
            variables: {
              VerifyCodeRequest: {
                code: verificationCode,
                userId: inDialog ? MFADialogState.userId : steps!.userId!,
              },
            },
          });
        } catch (error) {
          setToastMessage({
            snackbarMsg: `Something went wrong. Please try again!`,
            severity: 'error',
            isOpen: true,
          });
        }
      }
    };

    if (triggerVerification) {
      verify();
      setTriggerVerification(false);
    }
  }, [triggerVerification, verifyCodeResponse, loggedInUser]);

  const convertErrorDescriptionToHint = (desc: string) => {
    switch (desc) {
      case VerificationDescriptionCode.WRONG_CODE:
        return 'Invalid verification code, please try again.';
      case VerificationDescriptionCode.EXPIRED_CODE:
        return 'Verification code has expired, please resend the code.';
      case VerificationDescriptionCode.UNKNOWN:
        return 'Something went wrong, please try later.';
      default:
        return '';
    }
  };
  const errorHint = React.useMemo(
    () => convertErrorDescriptionToHint(verificationCodeError.message),
    [verificationCodeError.message]
  );

  React.useEffect(() => {
    if (verifyCodeResponse.data) {
      if (verifyCodeResponse.data.verifyCode?.isSuccess) {
        if (inDialog) {
          setToastMessage({
            snackbarMsg: editPage
              ? `Your phone number is verified and updated!`
              : 'Your phone number is verified!',
            severity: 'success',
            isOpen: true,
          });
          setIsOpenToast(true);
          // other workflow
          setMFADialogState({
            showMFADialog: false,
            userId: '',
            phoneNumber: '',
            verifyButtonClicked: false,
            skipForNow: false,
          });
        } else {
          // login workflow
          setSteps({
            step: 2,
            title: 'Set New Password',
            phone: '',
            snackbar: true,
            snackbarMessage: 'Your phone number is verified!',
          });
        }
      } else {
        const errorDesc =
          verifyCodeResponse.data?.verifyCode?.description ?? '';
        setVerificationCodeError({
          error: true,
          message: errorDesc,
        });
      }
    }
  }, [verifyCodeResponse.data, inDialog]);

  React.useEffect(() => {
    if (!verificationCode) {
      setVerificationCodeError({
        error: false,
        message: '',
      });
    }
  }, [verificationCode]);

  const checkVerificationCode = async () => {
    setTriggerVerification(true);
    setMFADialogState((prevState) => ({
      ...prevState,
      verifyButtonClicked: true,
    }));
  };

  const resendCode = async () => {
    try {
      await sendCodeMutation({
        variables: {
          SendCodeRequest: {
            phone: steps.phone,
            userId: inDialog ? MFADialogState.userId : steps!.userId!,
          },
        },
      });

      setToastMessage({
        severity: 'success',
        snackbarMsg: 'A verification code has been sent to you',
        isOpen: true,
      });
    } catch (error) {
      setToastMessage({
        snackbarMsg: `Something went wrong. Please try again!`,
        severity: 'error',
        isOpen: true,
      });
    } finally {
      setIsOpenToast(true);
    }
  };

  return (
    <div role="VerificationCodeForm" className={classes.root}>
      <form
        onSubmit={handleSubmit(checkVerificationCode)}
        className={classes.root}
      >
        <Paper
          className={classes.resetPwCard}
          elevation={inDialog ? 0 : 8}
          style={{
            padding: inDialog ? 0 : theme.spacing(5, 4, 5, 4),
            gap: inDialog ? theme.spacing(2) : theme.spacing(3),
          }}
        >
          <Typography className={classes.instructions}>
            {`Enter the 6-digit verification code that was sent to ${maskPhoneNumber(
              steps.phone
            )}. The code will expire in 5 minutes.`}
          </Typography>
          <div>
            <Typography
              className={
                verificationCodeError.error
                  ? `${classes.errorColor}  ${classes.inputLabel}`
                  : classes.inputLabel
              }
            >
              Code
            </Typography>
            <OutlinedInput
              fullWidth
              inputProps={{
                required: true,
              }}
              name={'verificationCode'}
              defaultValue=""
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              error={
                verificationCode?.length > 0 &&
                verificationCode.match(/^\d{6}$/i) == null
              }
              className={classes.nameInput}
              placeholder="Enter Verification code"
              classes={outlinedInputClasses}
            />
            {verificationCodeError.error && (
              <Typography color="error" className={classes.errorMsg}>
                {errorHint}
              </Typography>
            )}
          </div>
          <Button
            type="submit"
            color="primary"
            className={classes.submitButton}
          >
            Verify
          </Button>
          <div style={inDialog ? { textAlign: 'center' } : {}}>
            <ResendCodeButton resendCode={resendCode} />
          </div>
          {inDialog ? null : (
            <Button
              variant="text"
              color="primary"
              className={classes.textBtnWithBottomLine}
              startIcon={<NavigateNextIcon className={classes.navigateNext} />}
              onClick={() =>
                setSteps({
                  step: 0,
                  title: 'Verify Phone Number',
                  phone: '',
                  userId: inDialog ? MFADialogState.userId : steps!.userId!,
                })
              }
            >
              Back
            </Button>
          )}
        </Paper>
      </form>
    </div>
  );
};
