import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { VerifyPhoneNumberForm } from '../../scenes/Login/components/VerifyPhoneNumberForm';
import { VerificationCodeForm } from '../../scenes/Login/components/VerificationCodeForm';
import { useLocation } from 'react-router';
import { GlobalContext } from '../GlobalContext';
import { MFASteps } from '../../scenes/Login/View';

interface MFADialogProps {}

// type MFASteps = {
//   step: number;
//   title: string;
//   phone: string;
// };

export const MFADialog: React.FC<MFADialogProps> = ({}) => {
  const { setMFADialogState, loggedInUser } = React.useContext(GlobalContext);
  const classes = useStyles();

  const location = useLocation();

  const [editPage, setEditPage] = React.useState<boolean>(false);
  // const [showDialog, setShowDialog] = React.useState<boolean>(true);
  const [mfaSteps, setMfaSteps] = React.useState<MFASteps>({
    step: 0,
    title: 'Verify Your Phone Number',
    phone: loggedInUser?.phone || '',
  });
  const dialogContentMap: { [key: number]: JSX.Element } = {
    0: (
      <VerifyPhoneNumberForm
        setSteps={setMfaSteps}
        inDialog
        editPage={editPage}
      />
    ),
    1: (
      <VerificationCodeForm
        setSteps={setMfaSteps}
        steps={mfaSteps}
        inDialog
        editPage={editPage}
      />
    ),
  };
  const dialogActionMap: { [key: number]: JSX.Element | null } = {
    0: (
      <>
        {editPage ? (
          <DialogActions className={classes.dialogAction}>
            <Button
              onClick={() => {
                setMFADialogState({
                  showMFADialog: false,
                  userId: '',
                  phoneNumber: '',
                  verifyButtonClicked: false,
                  skipForNow: false,
                });
              }}
              className={classes.backButton}
            >
              Cancel
            </Button>
          </DialogActions>
        ) : undefined}
      </>
    ),
    1: (
      <DialogActions className={classes.dialogAction}>
        <Button
          onClick={() => {
            setMfaSteps({
              step: 0,
              title: 'Verify Your Phone Number',
              phone: '',
            });
          }}
          className={classes.backButton}
        >
          Back
        </Button>
      </DialogActions>
    ),
  };

  React.useEffect(() => {
    if (
      location.pathname.includes('/profile/edit') ||
      location.pathname.includes('/update')
    ) {
      setMfaSteps((prev) => ({
        ...prev,
        title: 'Edit and Verify Your Phone Number',
      }));
      setEditPage(true);
    }
  }, [location]);
  return (
    <div role="MFADialog" className={classes.root}>
      <Dialog
        className={classes.dialogContainer}
        open={true}
        onClose={() => {}}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="MFADialog-title"
          style={{
            borderBottom: '1px solid #D1D1D1',
          }}
        >
          {mfaSteps.title}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {dialogContentMap[mfaSteps.step]}
        </DialogContent>

        {dialogActionMap[mfaSteps.step]}
      </Dialog>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dialogContainer: {},
  dialogContent: {
    padding: theme.spacing(3, 3, 4, 3),
  },
  dialogAction: {
    borderTop: '1px solid #D1D1D1',
    justifyContent: 'flex-start',
    padding: theme.spacing(2, 0, 2, 3),
  },
  backButton: {
    background: theme.palette.grey[200],

    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,

    textTransform: 'capitalize',

    '&:hover': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
    },
  },
}));
