import { Button, OutlinedInput, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSMSMFA } from '../../../../hooks/useSMSMFA';
import { handleVerificationCode } from '../../../../sharePipe/normalizePhoneNumberInput';
import { useOutlinedInputStyles } from '../../../../styles/outlineInputStyle';
import { theme } from '../../../../themes/theme';
import { Forms } from '../../View';
import { useMFAStyles } from '../MFA';

interface SMSMFAProps {
  codeDestination: string;
  setRenderForm: React.Dispatch<React.SetStateAction<string>>;
  cognitoUser: object;
}

type VerificationCode = {
  verificationCode: string;
};

export const SMSMFA: React.FC<SMSMFAProps> = ({
  codeDestination,
  setRenderForm,
  cognitoUser,
}) => {
  const classes = useMFAStyles();

  const [verificationCode, setVerificationCode] = React.useState('');
  const { SMSMFAState, handleMFA } = useSMSMFA(cognitoUser, verificationCode);
  const color = SMSMFAState.error ? theme.palette.error.main : '';
  const outlinedInputClasses = useOutlinedInputStyles(color);
  // const outlinedInputClasses = useOutlinedInputStyles(
  //   SMSMFAState.error ? theme.palette.error.main : theme.palette.primary.main
  // );

  const {
    control,
    handleSubmit,
    getValues,
    register,
    errors,
  } = useForm<VerificationCode>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      verificationCode: '',
    },
  });

  const handleVerificationCodeChange = (e: any) => {
    setVerificationCode(handleVerificationCode(e.target.value));
  };

  return (
    <div role="SMSMFA" className={classes.root}>
      <form onSubmit={handleSubmit(handleMFA)} className={classes.root}>
        <Paper className={classes.resetPwCard} elevation={8}>
          <Typography className={classes.title}>
            Multi-factor Verification
          </Typography>

          <div className={classes.form}>
            <Typography className={classes.instructions}>
              {`Please enter the 6-digit verification code that was sent to ${codeDestination.slice(
                1
              )} to continue: `}
            </Typography>
            <div>
              <Typography className={classes.inputLabel}>Code</Typography>
              <OutlinedInput
                fullWidth
                inputProps={{
                  required: true,
                }}
                name={'verificationCode'}
                defaultValue=""
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                error={
                  verificationCode?.length > 0 &&
                  verificationCode.match(/^\d{6}$/i) == null
                }
                className={classes.nameInput}
                placeholder="Enter Verification code"
                classes={outlinedInputClasses}
              />
              {SMSMFAState.error && (
                <Typography color="error" className={classes.errorMsg}>
                  {SMSMFAState.error}
                </Typography>
              )}
            </div>
            <Button
              type="submit"
              color="primary"
              className={classes.submitButton}
            >
              Verify
            </Button>
          </div>
          <div className={classes.backButtonContainer}>
            <Button
              onClick={() => {
                console.log('Back button clicked...');
                setRenderForm(Forms.Login);
              }}
              className={classes.backButton}
            >
              Back
            </Button>
          </div>
        </Paper>
      </form>
    </div>
  );
};
