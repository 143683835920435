import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { Auth } from 'aws-amplify';
import { Button, Typography, Paper, OutlinedInput } from '@material-ui/core';
import { Forms } from '../../View';
import { useOutlinedInputStyles } from '../../../../styles/outlineInputStyle';
import { GlobalContext } from '../../../../components/GlobalContext';

interface Props {
  setRenderForm: React.Dispatch<React.SetStateAction<string>>;
  setCognitoUser: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  loginCard: {
    minHeight: 384,
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 8,
    padding: theme.spacing(5, 4, 5, 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow:
      '0px 8px 20px rgba(0, 0, 0, 0.16), 0px 1px 0px rgba(0, 0, 0, 0.04)',
  },
  inputLabel: {
    fontSize: 14,
    height: 24,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey['700'],
  },
  inputField: {
    height: 48,
    borderRadius: 8,
    fontSize: 16,
  },
  loginButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    height: 48,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 8,
  },
  forgotPwButton: {
    width: 'fit-content',
    padding: 0,
    height: 'fit-content',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    borderRadius: 0,
  },
  forgotPw: {
    fontSize: 14,
    fontWeight: 600,
    height: 24,
    borderBottom: '2px solid #434343',
    color: theme.palette.secondary.dark,
    '&:hover': {
      color: '#434343',
    },
    paddingBottom: theme.spacing(0.5),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  errorMsg: {
    fontSize: 12,
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
  },
}));

type Credentials = {
  username: string;
  password: string;
};

export const LoginForm = (props: Props) => {
  const { setMFADialogState } = React.useContext(GlobalContext);
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const {
    control,
    handleSubmit,
    register,
    errors,
    formState,
  } = useForm<Credentials>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const signIn = async ({ username, password }: Credentials) => {
    setError(null);
    try {
      setLoading(true);
      await Auth.signIn(username, password).then((user) => {
        // console.log('logged in user', user);
        props.setCognitoUser(user);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // for new user: verify user's phone number then set new password
          // props.setRenderForm(Forms.MFA);
          props.setRenderForm(Forms.NewPw);
        }
        if (user.challengeName === 'SMS_MFA') {
          // for MFA enabled user: verify the code sent to user's phone number
          props.setRenderForm(Forms.SMS_MFA);
        }
        setMFADialogState((prev) => ({ ...prev, skipForNow: false }));
      });
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
    // setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(signIn)} className={classes.root}>
      <Paper className={classes.loginCard} elevation={8}>
        <Typography data-testid={'form-title'} className={classes.inputLabel}>
          Login
        </Typography>
        <Controller
          name="username"
          control={control}
          render={({ onChange, value, name }) => (
            <OutlinedInput
              fullWidth
              autoFocus
              inputRef={register({ required: true, minLength: 1 })}
              name={name}
              value={value}
              onChange={onChange}
              error={!!errors.username?.message || !!errors.username?.type}
              className={clsx(classes.inputField, classes.marginBottom)}
              placeholder="jacksmith@clinicbrand.com"
              classes={outlinedInputClasses}
            />
          )}
        />
        <Typography className={classes.inputLabel}>Password</Typography>
        <Controller
          name="password"
          control={control}
          render={({ onChange, value, name }) => (
            <div className={classes.marginBottom}>
              <OutlinedInput
                fullWidth
                inputRef={register({ required: true, minLength: 1 })}
                name={name}
                value={value}
                onChange={onChange}
                error={!!errors.password?.message || !!errors.password?.type}
                type="password"
                className={classes.inputField}
                placeholder="Enter Password"
                classes={outlinedInputClasses}
              />
              {error && (
                <Typography color="error" className={classes.errorMsg}>
                  You have entered an invalid email or password
                </Typography>
              )}
            </div>
          )}
        />
        <Button type="submit" color="primary" className={classes.loginButton}>
          {loading ? 'Loading...' : 'Login'}
        </Button>
        <Button
          data-testid={'button-login'}
          className={classes.forgotPwButton}
          onClick={() => props.setRenderForm(Forms.ResetPw)}
        >
          <span className={classes.forgotPw}>Forgot password?</span>
        </Button>
      </Paper>
    </form>
  );
};
