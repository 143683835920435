import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {CareGapContext, useCareGapGlobalContext} from '../../../../CareGaps/Home/tabs/Population/context';
import { CareGapPatientTable } from '../../../../CareGaps/Home/tabs/Population/components/CareGapPatientTable';
import { CareGapsDrawer } from '../../../../CareGaps/Home/tabs/Population/components/CareGapsDrawer';
import { AllPatientHeaderContainer } from './components/AllPatientHeader';
import { AllPatientFilters } from './components/AllPatientFilters';
import { EmptyTableView } from '../../../../../components/EmptyTableView';
import { useLocation, useNavigate } from 'react-router';
import {
  ageOptions,
  careGapStatusOptions,
  engagementStatusOptions,
  futureAppointmentOptions,
  genderOptions,
} from '../../../../../enum/Panel/assignedPatientFilterOptions';
import {
  GetCareGapFilterOptionsQuery,
  useGetCareGapFilterOptionsQuery,
} from '../../../../CareGaps/Home/tabs/Population/components/CareGapCreateFilter/index.generated';
import { sortArrayObjectPipe } from '../../../../../sharePipe/sortArrayObjectPipe';
import { useSwitchPatientWithPagination } from '../../../ViewPanels/PanelPatients/hooks/useSwitchPatientWithPagination';
import { SubMainRegion } from '../../../../../components/SubMainRegion';
import { useLanguageFilter } from '../../../../../hooks/useLanguageFilter';
import { usePanelPatientsMeQuery } from '../../../../Panels/ViewPanels/PanelPatients/index.generated';
import { useKeyPatients } from '../../../ViewPanels/PanelPatients/hooks/useKeyPatients';
import { useUnlockPatientClosingPFS } from '../../../ViewPanels/PanelPatients/hooks/useUnlockPatientClosingPFS';
import { usePatientPanelContext } from '../../../LayoutBuilder/PatientPanelTabContext';
import { GroupByParent } from '../../../../../components/CustomSelectWithLayer/GroupByParent';
import { PatientFilterComponentIDs } from '../../../../../enum/store';
import { initialFilterState } from '../../../../../store/reducer/patientFilter';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  subClass: {
    flexGrow: 1,
  },
  closedDrawer: {
    display: 'none',
    transition: 'all 1s',
  },
  openedDrawer: {
    display: 'block',
    transition: 'all 3s',
  },
}));

interface AllPatientContainerProps {
  showMyOwn: string;
  isGetAllPatients: boolean;
  setNumOfPatients?: any;
}

export const AllPatientContainer = (props: AllPatientContainerProps) => {
  const classes = useStyles();
  const [openFilter, setOpenFilter] = React.useState(true);
  const [enableTable, setEnableTable] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const { isViewAllClicked, setIsViewAllClicked } = usePatientPanelContext();
  React.useEffect(() => {
    if (isViewAllClicked) {
      setIsViewAllClicked(false);
    }
  }, [isViewAllClicked]);

  const {
    showDrawer,
    drawerHandler,
    checkedPatient,
    value,
    switchDrawerTab,
    setCheckedPatient,
  } = useCareGapGlobalContext();
  const [applyFilterBtnIsClicked, setApplyFilterBtnIsClicked] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(true);
  const [displayedCount, setDisplayedCount] = React.useState(0);

  // option care gap options
  const openCareGapOptionsQuery = useGetCareGapFilterOptionsQuery();

  const getOpenCareGapOptions = (
    query: GetCareGapFilterOptionsQuery | undefined
  ) => {
    return query?.getCareGapFilterOptions;
  };

  const openCareGapOptions = React.useMemo(
    () => getOpenCareGapOptions(openCareGapOptionsQuery?.data),
    [openCareGapOptionsQuery.data]
  );

  const { languages } = useLanguageFilter(openCareGapOptions);

  const assignedPatientsFilterOptions = {
    // using hard coding options for Gender, Age, EngagementStatus and FutureAppointment
    Gender: genderOptions,
    Age: ageOptions,
    EngagementStatus: engagementStatusOptions,
    FutureAppointment: futureAppointmentOptions,
    CareGap: openCareGapOptions?.CareGap || [],
    Division:
      sortArrayObjectPipe(openCareGapOptions?.Divisions || [], 'asc', 'name') ||
      [],
    Clinics:
      sortArrayObjectPipe(openCareGapOptions?.Clinics || [], 'asc', 'name') ||
      [],
    Languages: languages || [],
    Payers: openCareGapOptions?.Payers || [],
    LineOfBusiness:
      GroupByParent(
        sortArrayObjectPipe(
          openCareGapOptions?.LineOfBusiness || [],
          'asc',
          'name'
        )
      ) || [],
    CareGapStatus: careGapStatusOptions,
    CallAttemptCount: openCareGapOptions?.CallAttemptCount || [],
  };

  const location = useLocation();
  const navigate = useNavigate();

  const { search } = location;

  const [skip, limit] = useMemo(() => {
    const params = new URLSearchParams(search);
    const skip =
      params.has('skip') && params.get('skip') !== ''
        ? parseInt(params.get('skip')!)
        : 0;
    const limit =
      params.has('limit') && params.get('limit') !== ''
        ? parseInt(params.get('limit')!)
        : 10;
    return [skip, limit];
  }, [search]);

  function updateQps({
    skip,
    limit,
  }: {
    skip: number | undefined;
    limit: number | undefined;
  }) {
    const params = new URLSearchParams();

    if (limit !== undefined) {
      params.set('limit', limit.toString());
    }
    if (skip !== undefined) {
      params.set('skip', skip.toString());
    }

    navigate(`?${params.toString()}`, { replace: true });
  }

  function onSetLimit(limit: number) {
    updateQps({ limit, skip });
  }

  function onSetSkip(skip: number) {
    updateQps({ limit, skip });
  }

  React.useEffect(() => {
    onSetSkip(0);
  }, [enableTable]);

  const [sortBy, setSortBy] = React.useState({
    field: 'fullName',
    method: 'asc' as const,
  });

  const { currentPage } = useSwitchPatientWithPagination(
    skip || 0,
    limit || 10,
    results,
    setCheckedPatient,
    checkedPatient
  );

  const patients = usePanelPatientsMeQuery({
    variables: {
      input: {
        ...initialFilterState,
        showMyOwn: props.showMyOwn,
      },
    },
  });
  React.useEffect(() => {
    if (props.setNumOfPatients) {
      if (!patients.called || patients.loading) {
      } else {
        props.setNumOfPatients(
          patients.data?.getPatientByCareTeamUserId.count ?? 0
        );
      }
    }
  }, [patients]);
  const [refresher, setRefresher] = React.useState(0);
  const { keyPatients, setKeyPatients } = useKeyPatients(checkedPatient);
  const [patientFaceInfo, setPatientFaceInfo] = React.useState<any>();
  const { setPatientFilterComponentId } = React.useContext(
      CareGapContext
  );

  useUnlockPatientClosingPFS(showDrawer, patientFaceInfo, '');

  useEffect(() => {
    // update table
    setPatientFilterComponentId( props.showMyOwn === 'YES'
        ? PatientFilterComponentIDs.MY_PATIENTS
        : PatientFilterComponentIDs.ALL_PATIENTS)
    setEnableTable(false);
    setTimeout(() => {
      if (refresher !== 0) {
        setEnableTable(true);
      }
    }, 100);

  }, [refresher]);

  return (
    <div className={classes.root}>
      <SubMainRegion
        header={
          <div>
            <AllPatientHeaderContainer
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              setIsSearching={setIsSearching}
              setEnableTable={setEnableTable}
              isSearching={isSearching}
            />
            <div
              className={
                openFilter ? classes.openedDrawer : classes.closedDrawer
              }
            >
              <AllPatientFilters
                defaultSortBy={{
                  field: 'fullName',
                  method: 'asc' as const,
                }}
                setSortBy={setSortBy}
                setEnableTable={setEnableTable}
                setIsSearching={setIsSearching}
                applyFilterBtnIsClicked={applyFilterBtnIsClicked}
                setApplyFilterBtnIsClicked={setApplyFilterBtnIsClicked}
                loading={loading}
                displayedCount={displayedCount}
                options={assignedPatientsFilterOptions}
                componentId={
                  props.showMyOwn === 'YES'
                    ? PatientFilterComponentIDs.MY_PATIENTS
                    : PatientFilterComponentIDs.ALL_PATIENTS
                }
              />
            </div>
          </div>
        }
        headerChange={openFilter}
      >
        {enableTable ? (
          <CareGapPatientTable
            sortBy={sortBy}
            setSortBy={setSortBy}
            isSearching={isSearching}
            setLoading={setLoading}
            setDisplayedCount={setDisplayedCount}
            limit={limit}
            skip={skip}
            setLimit={onSetLimit}
            setSkip={onSetSkip}
            setResults={setResults}
            showMyOwn={props.showMyOwn}
            isGetAllPatients={props.isGetAllPatients}
          />
        ) : (
          <EmptyTableView />
        )}
        {showDrawer && (
          <CareGapsDrawer
            allPatients={results}
            drawerHandler={drawerHandler}
            checkedPatient={checkedPatient}
            tabIndex={value}
            switchTab={switchDrawerTab}
            currentPage={currentPage}
            totalCount={displayedCount}
            limit={limit ?? 10}
            skip={skip ?? 0}
            setSkip={onSetSkip}
            refresher={refresher}
            setRefresher={setRefresher}
            setPatientFaceInfo={setPatientFaceInfo}
            keyPatients={keyPatients}
            setKeyPatients={setKeyPatients}
          />
        )}
      </SubMainRegion>
    </div>
  );
};
